import { motion } from "framer-motion";
import buss from "../../assets/bussines.jpg";
import Wrapper from "../Wrapper";
// import FoundationImg from "./FoundationSlide/FoundationImg";
// import FoundationContent from "./FoundationText/FondationContent";
import FoundationTabs from "./FoundationTabs";

const FoundationTest = () => {
  document.title = "TTL-Engineering |  Foundation Testing";
  return (
    <div className="mb-5">
      <div className="w-full h-[40vh] mb-10">
        <img className="w-[200%] h-full object-cover" src={buss} alt="" />
      </div>
      <Wrapper>
        <div className="flex text-center overflow-hidden flex-col mt-10 justify-center items-center mb-10">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Foundation Testing
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              ease: [0.22, 1, 0.36, 1],
              duration: 1.2,
              delay: 0.2,
            }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            TTL Engineering provides advanced Foundation Testing, Foundation
            testing involves assessing the strength and stability of building
            foundations using a range of traditional and cutting-edge tools.
            These methods, employing both conventional and sophisticated
            instruments, are widely recognized and utilized across various
            industries for evaluating infrastructure.
          </motion.p>
        </div>
        {/* <FoundationImg />
          <FoundationContent /> */}
      </Wrapper>
      <FoundationTabs />
    </div>
  );
};

export default FoundationTest;
