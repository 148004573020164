import { motion } from "framer-motion";
import buss from "../../assets/bussines.jpg";
import Wrapper from "../Wrapper";
// import NonDestImg from "./NonDestSlide/NonDestImg";
// import NonDestContent from "./NonDestText/NonDestContent";
import NonDestructiveTabs from "./NonDestructiveTabs";

const NonDestTest = () => {
  document.title = "TTL-Engineering | Non Destructive Testing";
  return (
    <div className="mb-5">
      <div className="w-full h-[40vh] mb-10">
        <img className="w-[200%] h-full object-cover" src={buss} alt="" />
      </div>
      <Wrapper>
        <div className="flex text-center overflow-hidden flex-col mt-10 justify-center items-center mb-10">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Non Destructive Testing
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            Non-Destructive Testing (NDT) is a highly favored method in the
            construction industry dealing with concrete & steel. NDT encompasses
            a range of inspection and testing techniques that assess the
            characteristics of the structures all without causing any damage.
          </motion.p>
        </div>
        {/* <NonDestImg />
        <NonDestContent /> */}
      </Wrapper>
      <NonDestructiveTabs />
    </div>
  );
};

export default NonDestTest;
