import { useEffect, useState } from "react";
import { RiArrowUpDoubleLine } from "react-icons/ri";

const TopToScroll = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const listenToScroll = () => {
    if (window.scrollY > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div
          className="main-scroll-top bg-[#232c65] rounded-full p-2 shadow-md text-[#fcfaf8] cursor-pointer"
          onClick={goToTop}
        >
          <div className="scroll-top">
            <span className="arrow-icon">
              <RiArrowUpDoubleLine className="w-10 h-10" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TopToScroll;
