/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../Wrapper";
import { Link } from "react-router-dom";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import { BiMenuAltRight } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showCatMenu, setShowCatMenu] = useState(false);
  const [show, setShow] = useState("translate-y-0");
  const [lastScrollY, setLastScrollY] = useState(0);

  // const controllerBar = () => {
  //   if (window.scrollY > 300) {
  //     if (window.scrollY > lastScrollY) {
  //       setShow("-translate-y-[90px]");
  //     } else {
  //       setShow("shadow-sm");
  //     }
  //   } else {
  //     setShow("translate-y-0");
  //   }
  //   setLastScrollY(window.scrollY);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", controllerBar);

  //   return () => {
  //     window.removeEventListener("scroll", controllerBar);
  //   };
  // }, [lastScrollY]);

  return (
    <nav
      className={`w-full h-[80px] md:h-[80px] bg-white flex items-center justify-between z-50 sticky top-0 transition-translate duration-300 shadow-md ${show}`}
    >
      <Wrapper className="h-[60px] flex items-center justify-between">
        <Link
          to="/"
          className="flex lg:gap-1 lg:ml-0 md:-ml-5 gap-2 items-center"
        >
          <div>
            <img
              className="logo sm:w-[230px] w-[180px]"
              src="/ttl-logo.jpeg"
              alt=""
            />
          </div>
        </Link>
        <MenuDesktop
          showCatMenu={showCatMenu}
          setShowCatMenu={setShowCatMenu}
        />

        {mobileMenu && (
          <MenuMobile
            showCatMenu={showCatMenu}
            setShowCatMenu={setShowCatMenu}
            setMobileMenu={setMobileMenu}
          />
        )}
        <div className="w-10 md:w-12 h-10 md:h-12 rounded-full flex md:hidden justify-center items-center cursor-pointer hover:bg-black/[0.05] relative -mr-2">
          {mobileMenu ? (
            <VscChromeClose
              className="text-[25px] text-black"
              onClick={() => setMobileMenu(false)}
            />
          ) : (
            <BiMenuAltRight
              className="text-[27px] text-black"
              onClick={() => setMobileMenu(true)}
            />
          )}
        </div>
      </Wrapper>
    </nav>
  );
};

export default Header;
