import { Route, Routes } from "react-router-dom";
import Home from "../Components/HomePage/Home";
import About from "../Components/AboutPage/About";
import ScrollToTop from "./ScrollToTop";
import Consultancy from "../Components/ConsultancyPage/Consultancy";
import GeoTechnical from "../Components/GeoTechPage/GeoTechnical";
import PavementSurvey from "../Components/PavementPage/PavementSurvey";
import Careers from "../Components/CareerPage/Careers";
import Contact from "../Components/ContactPage/Contact";
import Projects from "../Components/ProjectPage/Projects";
import FoundationTest from "../Components/FoundationTest/FoundationTest";
import NonDestTest from "../Components/NonDestructiveTest/NonDestTest";

const Routing = () => {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/geoTechnical" element={<GeoTechnical />} />
        <Route path="/pavementSurvey" element={<PavementSurvey />} />
        <Route path="/foundationTesting" element={<FoundationTest />} />
        <Route path="/nonDestructiveTesting" element={<NonDestTest />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default Routing;
