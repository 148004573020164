import { useState } from "react";
import { MdClose } from "react-icons/md";
import certificate1 from "../../../../assets/certificate/certificate1.jpg";
import ZoomOne from "./ZoomOne";

function CertificateOne() {
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleCertificateClick = (name) => {
    setSelectedCertificate(name);
  };

  const handleClosePreview = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className="container">
      <div className="certificates-container">
        <div className="certificates" onClick={() => handleCertificateClick("p-1")}>
          <img src={certificate1} alt="" />
          <h1 className="uppercase font-semibold">MSME Certificate</h1>
        </div>
      </div>

      <div
        className="certificates-preview z-[9999]"
        style={{ display: selectedCertificate ? "flex" : "none" }}
      >
        <div className={`preview ${selectedCertificate === "p-1" ? "active" : ""}`}>
          <MdClose className="close-icon" onClick={handleClosePreview} />
          <ZoomOne imgZoom={certificate1} />
          <h1 className="uppercase font-semibold">MSME Certificate</h1>
        </div>
      </div>
    </div>
  );
}

export default CertificateOne;
