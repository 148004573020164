import brochure from "../../Document/TTL Engineering_Brochure.pdf";

const Brochure = () => {
  return (
    <a
      href={brochure}
      download="TTL Engineering_Brochure"
      className="buttonDownload"
    >
      Brochure
    </a>
  );
};

export default Brochure;
