/* eslint-disable no-unused-vars */
// import ourTeam from "../Images/ourtem.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BiArrowBack } from "react-icons/bi";
import about1 from "../../assets/about/about1.jpg";
import about2 from "../../assets/about/about2.jpg";
import ceo from "../../assets/about/ceo.jpg";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import Testimonial from "./Testimonial";
import AboutVisions from "./AboutVisions";
import Certification from "./Certifications/Certification";

const About = () => {
  document.title = "TTL-Engineering | About Us";
  return (
    <div>
      <div className="relative text-white text-[20px] w-full max-w-full mx-auto">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <div
              onClick={clickHandler}
              className="absolute right-[31px] md:right-[51px] bottom-0 w-[30px]
             md:w-[50px] h-[30px] md:h-[50px] bg-black z-10 flex items-center
              justify-center cursor-pointer hover:opacity-90"
            >
              <BiArrowBack className="text-sm md:text-lg" />
            </div>
          )}
          renderArrowNext={(clickHandler, hasNext) => (
            <div
              onClick={clickHandler}
              className="absolute right-0 bottom-0 w-[30px] md:w-[50px] h-[30px]
             md:h-[50px] bg-black z-10 flex items-center
              justify-center cursor-pointer hover:opacity-90"
            >
              <BiArrowBack className="text-sm md:text-lg rotate-180" />
            </div>
          )}
        >
          <div className="flex justify-center items-center">
            <img
              className="h-[40vh] aspect-auto object-cover relative"
              src={about2}
            />
          </div>
          <div className="flex justify-center items-center">
            <img
              className="h-[40vh] aspect-auto object-cover relative md:object-top"
              src={about1}
            />
          </div>
        </Carousel>
      </div>
      <Wrapper>
        <div className="flex text-center overflow-hidden flex-col mt-10 justify-center items-center mb-10">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              About
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            TTL Engineering Private Limited (TTL) is a leading global
            infrastructure engineering consultancy providing design, planning,
            engineering, consultancy and construction solutions for Roads,
            Metros, Bridges, Tunnels, Buildings Water and Airports,. We also
            provide solutions services in Urban Planning, Capacity Building,
            Environmental Engineering & Social Issues, all including
            Construction Material Testing and Geotechnical Investigations.
          </motion.p>
        </div>
      </Wrapper>

      <div className="w-full">
        <Wrapper className="mt-10 w-full grid lg:grid-cols-2 grid-cols-1 gap-10 overflow-x-hidden overflow-y-auto">
          <motion.div
            initial={{ x: "-20%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1 }}
          >
            <img className="w-full md:h-[53vh] object-cover" src={ceo} alt="" />
          </motion.div>
          <motion.div
            initial={{ x: "20%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 2 }}
            className="mr-2 w-full"
          >
            <h1 className=" text-[#232c65] font-bold text-center text-4xl mb-3">
              MANAGING DIRECTOR
            </h1>
            <p className=" font-light">
              <span className=" font-bold text-[17.5px]">
                Mr. Vikas Kirodiwal,
              </span>{" "}
              our Managing Director, brings with him an extraordinary 18 years
              of specialized experience in pavement studies. His professional
              journey commenced at HALCROW CONSULTING INDIA LIMITED, where he
              demonstrated exceptional skills as traffic expert. Building on
              this strong foundation, Mr. Vikas further honed his expertise at
              SMEC INDIA PRIVATE LIMITED as a Pavement Engineer. There, he was
              entrusted with critical responsibilities such as estimating
              revenue projections.
            </p>
            <p className="mt-3">
              <span className=" font-semibold text-[18px]">In 2016, </span>
              Mr. Vikas embarked on an entrepreneurial path by founding TTL
              ENGINEERING PRIVATE LIMITED, infusing his vast experience and
              innovative thinking into his own venture. His profound
              understanding of traffic and site surveys extends across diverse
              sectors, including highways, railways & metros. A visionary
              leader, Mr. Vikas is celebrated not only for his technical acumen
              but also for his unwavering commitment to his team. He fosters a
              work environment that prioritizes continuous learning, growth, and
              innovation, while his trust in his employees and dedication to
              fairness have been pivotal in driving the company’s success.
            </p>
          </motion.div>
        </Wrapper>
        <AboutVisions />
        <Testimonial />
        <Certification />
      </div>
    </div>
  );
};

export default About;
