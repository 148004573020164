import { motion } from "framer-motion";
import JobOne from "./JobOne";
import JobTwo from "./JobTwo";
import JobThree from "./JobThree";

const JobApply = () => {
  return (
    <div>
      <section className="w-full h-full lg:-mt-4">
        <div className="flex text-center flex-col justify-center items-center">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Open Positions
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "80%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              ease: [0.22, 1, 0.36, 1],
              duration: 1.2,
              delay: 0.2,
            }}
            className=" w-full text-center text-[#222] sm:text-[18px] text-[14px] mt-3"
          >
            Join our talented team of civil engineers and help shape the
            infrastructure of the future.
          </motion.p>
        </div>
        <div className="mx-auto grid items-start gap-8 sm:max-w-4xl sm:grid-cols-2 md:gap-8 lg:max-w-7xl lg:grid-cols-3 mt-10">
          <JobOne />
          <JobTwo />
          <JobThree />
        </div>
      </section>
    </div>
  );
};

export default JobApply;
