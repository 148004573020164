import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";
import para from "../../assets/para.jpg";

const ParallaxEffect = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="mt-20 w-full h-[50vh]">
      <div id="parallax-world-of-ugg">
        <section>
          <div
            className="parallax-one"
            style={{ backgroundImage: `url(${para})` }}
          >
            {/* bg-[#bdd2e3] */}
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              <div className="max-w-8xl sm:gap-4 gap-2 mx-auto text-start grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 xl:-mt-[3em] sm:-mt-[6em] -mt-[7em]">
                <div className="flex mx-auto gap-3 items-center">
                  <h2 className="text-[#232c65] font-semibold md:text-6xl text-3xl">
                    {counter && (
                      <CountUp start={0} end={30} duration={4.75}></CountUp>
                    )}
                    +
                  </h2>
                  <h2 className="md:text-4xl text-[#f2f2f2] text-xl text-start font-semibold">
                    Experts
                  </h2>
                </div>
                <div className="flex mx-auto gap-3 sm:items-center items-start">
                  <h2 className="text-[#232c65] font-semibold md:text-6xl text-3xl">
                    {counter && (
                      <CountUp start={0} end={150} duration={4.75}></CountUp>
                    )}
                    +
                  </h2>
                  <h2 className="md:text-4xl text-[#f2f2f2] text-xl text-start font-semibold">
                    Trusted <br />
                    Client
                  </h2>
                </div>
                <div className="flex mx-auto gap-3 items-center">
                  <h2 className="text-[#232c65] font-semibold md:text-6xl text-3xl">
                    {counter && (
                      <CountUp start={0} end={500} duration={4.75}></CountUp>
                    )}
                    +
                  </h2>
                  <h2 className="md:text-4xl text-[#f2f2f2] text-xl text-start font-semibold">
                    Jobs <br />
                    Complete
                  </h2>
                </div>
                <div className="flex mx-auto gap-3 items-center">
                  <h2 className="text-[#232c65] font-semibold md:text-5xl text-3xl">
                    {counter && (
                      <CountUp start={0} end={30} duration={4.75}></CountUp>
                    )}
                    +
                  </h2>
                  <h2 className="md:text-4xl text-[#f2f2f2] text-xl text-start font-semibold">
                    States & UT <br />
                    Covered
                  </h2>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ParallaxEffect;
