/* eslint-disable react/no-unescaped-entities */
import project from "../../assets/bussines.jpg";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import DesktopTabs from "./DesktopTabs";
import MobileTabs from "./MobileTabs";

const Projects = () => {
  document.title = "TTL-Engineering | Project";

  return (
    <div className="">
      <div className="w-full h-[40vh] mb-16">
        <img className=" w-full h-full object-cover" src={project} alt="" />
      </div>
      <Wrapper>
        <div className="flex text-center overflow-hidden flex-col mt-10 justify-center items-center mb-14">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Featured Project
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              ease: [0.22, 1, 0.36, 1],
              duration: 1.2,
              delay: 0.2,
            }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            Explore TTL engineering's portfolio of successful projects,
            showcasing our expertise in Geotechnical, Foundation, Pavement
            testing and more. Discover how our advanced techniques and
            state-of-the-art equipment deliver reliable and high- quality
            engineering solutions.
          </motion.p>
        </div>
      </Wrapper>
      <Wrapper className="lg:hidden block">
        <MobileTabs />
      </Wrapper>
      <div className="lg:block hidden">
        <DesktopTabs />
      </div>
    </div>
  );
};

export default Projects;
