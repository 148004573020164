/* eslint-disable react/no-unescaped-entities */

const LabTestContent = () => {
  return (
    <div className="textContentScroller relative mt-10 max-h-[100vh] overflow-y-auto overflow-x-hidden">
      <div className="flex text-center my-5 justify-center items-center ">
        <h1 className="sm:text-5xl text-3xl text-[#222]">
          Laboratory Test on Soil
        </h1>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Index Peoperty of soil
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Residences which help to assess the engineering conduct of soil and
          which assist in determining the category of soil appropriately are
          termed as Index residences of soil. Index properties of soil consist
          of various indices which help determine the engineering behavior
          including Strength, Load-bearing capacity, Swelling and Shrinkage,
          Settlement etc.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          California Bearing Ratio
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The California Bearing Ratio (CBR) is a degree of the energy of the
          subgrade of a street or other paved vicinity, and of the materials
          utilized in its creation.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Triaxial Shear Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The reason of the numerous methods is to degree the triaxial shear
          strengths of soil specimens subjected to distinct drainage situations
          within the subject.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Direct Shear Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The direct shear check is broadly used as it is simple to perform and
          may be done on undisturbed or remoulded soil sample.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Consolidation Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Soil consolidation refers back to the mechanical method by using which
          soil adjustments volume steadily in response to a exchange in strain.
          This occurs because soil is a 3-phase fabric, comprising soil grains
          and pore fluid, generally groundwater.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Swelling Pressure
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The expansive clays increase in their volume when they arrive in
          contact with water because of surface properties of these clay kinds.
          The strain which the expansive soil exerts, if it isn't allowed to
          swell or the extent exchange of the soil is arrested, is called
          Swelling stress of Soil.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Unconsolidated Undrained (UU) triaxial
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Unconsolidated Undrained (UU) triaxial test presents undrained
          strain-strain response of a cylindrical soil specimen below triaxial
          compression loading without consolidating the specimen. It also offers
          the undrained shear energy parameters via acting the assessments on
          specific confining pressures.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Proctor Density Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px] mb-5">
          The take a look at is known as the Proctor compaction test in honor of
          Ralph Roscoe Proctor. In 1933, he confirmed that the dry density of
          soil for given compaction relies upon on the quantity of water within
          the soil on the time of compaction.
        </p>
        <p className="textContent md:text-[18px] text-[15px] mb-5">
          The Proctor compaction test is completed to decide the most dry
          density of soil samples. It determines the ideal moisture content at
          which a given sample becomes densest and reap its maximum dry density.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Standard and Modified Compaction Tests
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Laboratory compaction tests provide the basis for figuring out the
          percent of compaction and water content had to acquire the required
          engineering houses, and for controlling production to guarantee that
          the specified compaction and water contents are accomplished.
        </p>
      </div>

      <div className="flex text-center my-5 justify-center items-center ">
        <h1 className="sm:text-5xl text-3xl text-[#222]">
          Laboratory Test on Rock
        </h1>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Point Load Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The point load test (PLT) is a generic rock mechanics checking out
          manner used for the calculation of a rock strength index. This index
          may be used to estimate other rock power parameters.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Unconfined Compressive Strength
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Unconfined Compressive electricity (UCS) stands for the maximum axial
          compressive pressure that a cohesive soil specimen can endure beneath
          0 confining strain. Unconfined compression test is one of the fastest
          and most inexpensive techniques of measuring shear electricity of
          clayey soil.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Poisson's Ratio
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Poisson's Ratio is a degree of transverse strain against axial
          pressure while uniaxial stress is implemented. take a look at system:
          The specimen is clamped into the grips of the Instron usual tester.
          strain measuring devices are connected to the specimen, one axial and
          one transverse.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Modulus of Elasticity
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Modulus of Elasticity, also called Elastic Modulus or actually
          Modulus, is the dimension of a fabric's elasticity. Elastic modulus
          quantifies a fabric's resistance to non-everlasting, or elastic,
          deformation. whilst beneath pressure, materials will first showcase
          elastic residences: the strain causes them to deform, however the
          fabric will go back to its preceding kingdom after the pressure is
          removed.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Brazilian Strength Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The Brazilian test is an oblique approach of assessing the tensile
          strength of rocks, based on the statement that most rocks in biaxial
          strain fields fail in anxiety when one most important strain is
          compressive.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Slake Durability
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Slake sturdiness of rock is the resistance of the rock against
          slaking, i.e., disintegration under cyclic wetting and drying
          strategies.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Cerchar Abrasion Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          This take a look at evaluates the abrasiveness of rock specimens,
          aiding within the type and characterization of intact rock. it is
          often applied to optimize rock crushing techniques and machinery
          selection, as well as to predict put on for TBM bits in tunneling
          programs.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Triaxial Compression
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The triaxial compression check a check wherein a cylindrical specimen
          of soil or rock encased in an impervious membrane is subjected to a
          confining pressure and then loaded axially to failure in compression.
        </p>
      </div>
    </div>
  );
};

export default LabTestContent;
