import { motion } from "framer-motion";
import team1 from "../../../assets/career/team1.jpg";
import team2 from "../../../assets/career/team2.jpg";
import team3 from "../../../assets/career/team3.jpg";

const CompanyCulture = () => {
  return (
    <div>
      <div className="w-full h-full lg:-mt-4 lg:pr-0 pr-2">
        <div className="flex text-center flex-col justify-center items-center">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Our Company Culture
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "80%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              ease: [0.22, 1, 0.36, 1],
              duration: 1.2,
              delay: 0.2,
            }}
            className=" w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            At TTL Engineering, our company culture fosters innovation,
            collaboration, and professional growth. We value diversity,
            encourage continuous learning, and create a supportive environment
            where every team member can thrive and contribute to our success.
          </motion.p>
        </div>
        <motion.div
          initial={{ y: "10%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
          className="mx-auto grid max-w-6xl grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8"
        >
          <div className="relative group overflow-hidden rounded-lg">
            <img
              alt="Team"
              className="mx-auto aspect-video overflow-hidden rounded-lg object-cover object-top"
              height={300}
              src={team2}
              width={400}
            />
          </div>
          <div className="relative group overflow-hidden rounded-lg">
            <img
              alt="Office"
              className="mx-auto aspect-video overflow-hidden rounded-lg object-cover object-center"
              height={300}
              src={team1}
              width={400}
            />
          </div>
          <div className="relative group overflow-hidden rounded-lg">
            <img
              alt="Office"
              className="mx-auto aspect-video overflow-hidden rounded-lg object-cover object-center"
              height={300}
              src={team3}
              width={400}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CompanyCulture;
