import { motion } from "framer-motion";
import { useState } from "react";
import CompanyBenefit from "./CareerPath/CompanyBenefit";
import JobApply from "./WorkWithUs/JobApply";
import CompanyCulture from "./CareerPath/CompanyCulture";

const CarMobileTab = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNum) => {
    setActiveTab(tabNum);
  };

  return (
    <motion.div
      initial={{ y: "10%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
      className="pro_contain"
    >
      <div
        className="pro_tabs"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.dataset.tab) {
            handleClick(Number(e.target.dataset.tab));
          }
        }}
      >
        <a
          className={`pro_tab tab-1 ${activeTab === 1 ? "active" : ""}`}
          href="#"
          data-tab="1"
        >
          Careers Path
        </a>
        <a
          className={`pro_tab tab-2 ${activeTab === 2 ? "active" : ""}`}
          href="#"
          data-tab="2"
        >
          Work With Us
        </a>

        <span className="pro_highlighter"></span>
      </div>

      <div className="pro_content">
        <div
          className={`pro_content__section ${activeTab === 1 ? "visible" : ""}`}
          data-tab="1"
        >
          <div className="textContentScroller relative max-h-[100vh] overflow-y-auto overflow-x-hidden">
            <CompanyCulture />
            <CompanyBenefit />
          </div>
        </div>
        <div
          className={`pro_content__section ${activeTab === 2 ? "visible" : ""}`}
          data-tab="2"
        >
          <JobApply />
        </div>
      </div>
    </motion.div>
  );
};

export default CarMobileTab;
