import { GoDot } from "react-icons/go";

const consultContent = () => {
  return (
    <div className="textContentScroller relative mt-10">
      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Design and Engineering
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          Being one of the prime design and engineering houses in the country,
          many clients come to us for comprehensive design and engineering
          services. These services cover preliminary design, detailed design,
          detailed engineering, architecture & MEP services, and the preparation
          of engineering drawings.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Transportation Planning
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          We provide a comprehensive service to our clients from Project
          Inception to Implementation that combines transportation planning &
          modelling with other disciplines and have flexibility to accommodate
          further growth.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Traffic and Revenue Studies
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          We provide integrated traffic and revenue projection studies for
          highways, ports, airports, and other infrastructure projects which
          enable stakeholders to procure, fund, invest in, or divest from large
          infrastructure projects.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Environmental and Social Impact Assessment Studies
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          At TTL Engineering, our Environmental and Social Experts undertake in
          depth Environmental and Social Impact Assessment Studies including
          developing Environmental Management Plans (EMP), Resettlement Action
          Plan (RAP) and Institutional Strengthening Frameworks for both non
          funded (private & government sector) & multilateral agencies (the ADB
          and the World Bank) funded projects in highways, airports, energy,
          transportation, railways, water and sanitation sectors.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Construction Management
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          This includes complete onsite management of the project, including
          quantity estimation, quantity surveying, approving contractors’ bills,
          and final submission of post-build drawings, helping our clients
          complete their prestigious projects on time, within budget, and
          meeting quality norms.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Environmental Management Services
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          Today, environment care is an integral part of project management and
          nearly all infrastructure projects require assessing and analyzing
          this aspect in order to develop sustainable facilities. At TTL
          Engineering, we have specialists who have extensive experience in
          providing environment project management and supervision services to
          both, public and private sector organizations, operating in sectors
          such as roads, highway and railways.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Financial Advisory
        </h1>
        <p className="textContent mb-5 md:text-[18px] text-[15px]">
          We also provide financial structuring of a project including proposed
          sources of finance and risk mitigation.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Urban Planning
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          We We are adept at applying international standard planning concepts
          and adapting those to the heritage, aesthetics, economics, and
          financial needs of Urban India. This is used to offer help to
          municipalities and their agencies to improve the functioning of cities
          & towns. We also provide multi-modal transport planning solutions
          using cutting-edge technology for existing developments/cities as well
          as new developments.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          PPP Advisory
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Our team brings unique ideas; effective marketing to potential
          bidders; along with transparent, well-documented, and quick processes
          that ensure the best long-term value for government entities when they
          structure Public Private Partnership projects. We also offer advisory
          services to private sector clients desirous of bidding for
          infrastructure projects through the PPP route.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Due Diligence
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          We offer technical, financial, and commercial due diligence for
          projects on behalf of investors, lenders, and investees.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Project Structuring
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The art of balancing the interests of diverse stakeholders, addressing
          legal and regulatory issues, and capital structuring which is then
          converted into contracts that clearly define the roles,
          responsibilities, and risks allocated to each partner.
        </p>
      </div>

      <div>
        <h1 className="text-2xl font-semibold text-[#2d3df7]">
          Asset Management Propositions
        </h1>
        <p className="flex items-center sm:text-[18px] text-[16px] mt-4 sm:px-4">
          <span>
            <GoDot />
          </span>
          Asset Management Strategy & Planning
        </p>
        <p className="flex sm:items-center items-start sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Asset Management Decision Making & Operational Optimization
        </p>
        <p className="flex sm:items-center items-start sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Life Cycle Planning & Asset Management Systems
        </p>
        <p className="flex items-center sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Asset Information / Condition Assessment
        </p>
        <p className="flex items-center sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Organization & People
        </p>
        <p className="flex items-center sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Risk & Review
        </p>
        <p className="flex items-center sm:text-[18px] text-[16px] sm:px-4">
          <span>
            <GoDot />
          </span>
          Asset Management/ O&M
        </p>
      </div>
    </div>
  );
};

export default consultContent;
