/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Wrapper from "../Wrapper";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import { motion } from "framer-motion";
import nitin from "../../assets/ourleadership/nitin.jpg";
import mohit from "../../assets/ourleadership/mohit.jpg";
import anujay from "../../assets/ourleadership/anujay.jpg";

const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([
    {
      id: 1,
      name: "Mr. Nitin Upreti",
      tittle: "Executive Director",
      text: "Mr. Nitin, with 15 years of experience at PWC, Delloite, BDO, and Reliance, oversees our global advisory and financial operations. He specializes in infrastructure transactions across diverse regions, including India, Africa, the Middle East, and Southeast Asia.",
      avatar: nitin,
      linkedin: "",
      education1: "B.Tech- ME",
      education2: "Punjab Engineering College",
    },
    {
      id: 2,
      name: "Mr. Anujay Dutt",
      tittle: "General Manager",
      text: "Mr. Anujay, Bringing over 8+ years of extensive experience, he possesses a wealth of expertise focused on strategic initiatives and advancing business growth. His expertise and background will undoubtedly contribute significantly to our team.",
      avatar: anujay,
      linkedin: "https://www.linkedin.com/in/anujay-dutt-14053a13a/",
      education1: "PGDM-BM(XLRI Jamshedpur)",
      education2: "M.Tech- Trans., B.tech- CE",
    },
    {
      id: 3,
      name: "Mr. Mohit Chaturvedi",
      tittle: "Technical Director",
      text: "Mr. Mohit advises on project conceptualization and execution. With over 20 years in highway engineering, he has led projects in India, Maldives, Dubai, and Africa. His expertise includes construction management, project coordination.",
      avatar: mohit,
      linkedin: "",
      education1: "B.Tech- CE",
      education2: "Indira Gandhi National",
      education3: "University",
    },
  ]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1023, min: 664 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 663, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Wrapper className="my-1">
        <div className="flex flex-col items-center text-center justify-center overflow-hidden">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Our Leadership
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px] leading-tight"
          >
            Our success stems from the collective expertise and dedication of
            our strong team, delivering best-in-class solutions that exceed both
            industry standards and client expectations.
          </motion.p>
        </div>

        <Carousel
          // containerClass="-mx-[10px]"
          itemClass="px-[10px]"
          responsive={responsive}
        >
          {testimonial.map((item) => {
            return (
              <div key={item.id} className="swiper mySwiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="swiper-client-msg">
                      <p>
                        <RiDoubleQuotesL className="text-[#232c65] inline-block absolute lg:top-5 top-2 lg:left-5 left-2 lg:text-4xl text-2xl" />
                        {item.text}
                        <RiDoubleQuotesR className="text-[#232c65] inline-block absolute lg:bottom-5 bottom-4 lg:right-5 right-2 lg:text-4xl text-2xl" />
                      </p>
                    </div>
                    <div className="swiper-client-data">
                      <figure>
                        <img className="object-top" src={item.avatar} alt="" />
                      </figure>
                      <div className="client-data-details">
                        <h1 className="font-semibold sm:text-2xl text-xl text-[#232c65] ]">
                          <a href={item.linkedin} target="_blank">
                            <h1 className=" inline-block hover:border-b-2 border-[#232c65]">
                              {item.name}
                            </h1>
                          </a>
                        </h1>
                        <p className="font-semibold">{item.tittle}</p>
                        <p className=" text-sm">{item.education1}</p>
                        <p className=" text-sm">{item.education2}</p>
                        <p className=" text-sm">{item.education3}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </Wrapper>
    </>
  );
};

export default Testimonial;
