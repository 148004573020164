/* eslint-disable react/no-unescaped-entities */
const PaveProject = () => {
  return (
    <div className="textContentScroller relative max-h-[70vh] overflow-y-auto overflow-x-hidden">
      <div className="overflow-x-auto tableScroll md:text-[15px] text-sm">
        <table>
          <tr>
            <th>S.N</th>
            <th>Project Tittle</th>
            <th>Client</th>
            <th>State</th>
            <th>Status</th>
            <th>Test Parameter</th>
          </tr>
          {/* first */}
          <tr>
            <td>1</td>
            <td>
              Bridge Load, Network Survey Vehicle (NSV), Falling Weight
              Deflectometer (FWD), NDT and Retro Reflectometer survey at Up
              Gradation and Rehabilitation to Two Lane with Paved Shoulder of
              Barmer Gagariya Section from Km. 0.972 to Km. 69.910. of Nh-25 Ext
              (Project Length 68.93 km).
            </td>
            <td>Tan Singh Chouhan Company</td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>
              Bridge Load, Network Survey Vehicle, Falling Weight Deflectometer,
              Retro Reflectometer survey and NDT
            </td>
          </tr>
          {/* second */}
          <tr>
            <td>2</td>
            <td>
              Network Survey Vehicle (NSV), Retro Reflectivity Test (RRT) & Non
              destructive test at Baran Aklera Section (Total length-76.68 km)
              Road work Project.
            </td>
            <td>Vishnu Prakash R Pungilia Limited</td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Network Survey Vehicle, Retro Reflectometer survey and NDT</td>
          </tr>
          {/* third */}
          <tr>
            <td>3</td>
            <td>
              MBIU Services for bridge inspection in 4 states (Rajasthan,
              Maharashtra, Telangana, Karnataka)- Providing machineries and
              qualified operators as per Detailed scope of work on hire basis
              suitable to perform the services.
            </td>
            <td>Interise Project Management Pvt. Ltd.</td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Mobile Bridge Inspection unit</td>
          </tr>
          {/* fourth */}
          <tr>
            <td>4</td>
            <td>
              MBIU service for bridge inspection for Kosi Bridge across kosi
              river including its approaches, the guide bound and afflux Bund on
              NH-57 in the state of Bihar.
            </td>
            <td>Cube Highways</td>
            <td> Bihar</td>
            <td>Completed</td>
            <td>Mobile Bridge Inspection unit</td>
          </tr>
          {/* fifth */}
          <tr>
            <td>5</td>
            <td>
              MBIU service for bridge inspection for athang jammu udhampur
              section og NH-44 from 15.200 km to km 16.452 od udhampur bypass in
              Jammu & Kashmir.
            </td>
            <td>Cube Highways</td>
            <td>Jammu & Kashmir</td>
            <td>Completed</td>
            <td>Mobile Bridge Inspection unit</td>
          </tr>
          {/* sixth */}
          <tr>
            <td>6</td>
            <td>
              MBIU service for bridge inspection Charki Dadri 6 lane section pkg
              1 & 3 in the state of Haryana.
            </td>
            <td>FP India Project Management Consultancy Services Pvt. Ltd.</td>
            <td>Haryana</td>
            <td>Completed</td>
            <td>Mobile Bridge Inspection unit</td>
          </tr>
          {/* seven */}
          <tr>
            <td>7</td>
            <td>
              MBIU for Consultancy Services for Authority's Engineer for
              Supervision of Construction of 8-lane access controlled Greenfield
              Delhi Vadodara Expressway (NH-148N) starting at Sohna-Gurugram to
              Junction with Keshopura Road (Ch. 0+000 to Ch. 151+840) Package 1
              & 2 in the State of Rajasthan.
            </td>
            <td>FP India Project Management Consultancy Services Pvt. Ltd.</td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Mobile Bridge Inspection unit</td>
          </tr>
          {/* eight */}
          <tr>
            <td>8</td>
            <td>
              Network Survey Vehicle (NSV) and Retro Reflectivity Test (RRT) on
              the Project – 8-lane access controlled Greenfield Delhi-Vadodara
              Expressway Pkg6 & Pkg7 & Pkg8 (Total Length-763.76 km).
            </td>
            <td>
              Intercontinental Consultants and Technocrats Private Limited
            </td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Network Survey Vehicle and Retro Reflectometer survey</td>
          </tr>
          {/* nine */}
          <tr>
            <td>9</td>
            <td>
              Road Condition Survey using Network Survey Vehicle (NSV) and Retro
              Reflectivity Test (RRT) on the Project 6-lane access-controlled
              Greenfield highway from Junction with Jind-Safidon road (SH-14)
              near Kheri village to Junction with NH- 334B (Charkhi
              Dadri-Jhajjar section) near Charkhi Dadri Ch. 80+000 to Ch.
              165+000 Package-04,05 & 06 (Total Length-516 km).
            </td>
            <td>
              Intercontinental Consultants and Technocrats Private Limited
            </td>
            <td>Haryana</td>
            <td>Completed</td>
            <td>Network Survey Vehicle and Retro Reflectometer survey</td>
          </tr>
          {/* ten */}
          <tr>
            <td>10</td>
            <td>
              Network Survey Vehicle (NSV) & Retro Road Condition Survey of
              Eight lane access-controlled Expressway from Rajasthan/MP Border
              to Jodmi village of Mandsaur district (Ch. 452+420 to 484+420;
              design Ch. 0+000 to Ch. 32+000) (Package-17) and (design Ch from
              Km 65.000 to Km 94.500) section of Delhi- Vadodara Greenfield
              Alignment (NH-148N) in the State of Madhya Pradesh on EPC Mode
              (Package-19)” (Total Length-492 km).
            </td>
            <td>
              Intercontinental Consultants and Technocrats Private Limited
            </td>
            <td>Madhya Pradesh</td>
            <td>Completed</td>
            <td>Network Survey Vehicle and Retro Reflectometer survey</td>
          </tr>
          {/* eleven */}
          <tr>
            <td>11</td>
            <td>
              Road Condition Survey using Network Survey Vehicle (NSV), Falling
              Weight Deflectometer (FWD) and Retro Reflectivity Test (RRT) on
              the Project – Rajauli-Bakhtiyarpur Section New NH-20 Package II
              (Km 4.0405 to Km 101.630) (Total Length 47.225) Km.
            </td>
            <td>Intercontinental Consultants and Technocrats Pvt. Ltd.</td>
            <td>Bihar</td>
            <td>Completed</td>
            <td>
              Network Survey Vehicle, Falling Weight Deflectometer and Retro
              Reflectometer survey
            </td>
          </tr>
          {/* twelve */}
          <tr>
            <td>12</td>
            <td>
              Falling Weight Deflectometer (FWD) survey on the Project 6-lane
              access-controlled Greenfield highway from Junction with
              Jind-Safidon road (SH-14) near Kheri village to Junction with NH-
              334B (Charkhi Dadri-Jhajjar section) near Charkhi Dadri Ch. 80+000
              to Ch. 165+000 Package-04,05 & 06 (Total Length-516 km).
            </td>
            <td>
              Intercontinental Consultants and Technocrats Private Limited
            </td>
            <td>Haryana</td>
            <td>Completed</td>
            <td>Falling Weight Deflectometer</td>
          </tr>
          {/* thirteen */}
          <tr>
            <td>13</td>
            <td>
              Network Survey Vehicle (NSV) and Retro Reflectivity Test (RRT) for
              the work of "Rehabilitation and Up-gradation to 2-lane with paved
              shoulders /4-lane configuration of Ras-Beawar-Asind-Mandal Section
              (km. 00.000 to km. 116.750) of NH-158 in the State of Rajasthan
              under Green National Highways Corridor Project (GNHCP) with the
              loan assistance of World Bank on EPC mode. (Pkg-02 & 03).
            </td>
            <td>
              Intercontinental Consultants and Technocrats Private Limited
            </td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Retro Reflectometer survey</td>
          </tr>
          {/* fourteen */}
          <tr>
            <td>14</td>
            <td>
              Network Survey Vehicle (NSV) at Mumbai Coastal Road Project (MCRP)
              (Total length-29.80 km)
            </td>
            <td>S & R Geotechniques Pvt Ltd</td>
            <td>Maharashtra</td>
            <td>Completed</td>
            <td>Network Survey Vehicle</td>
          </tr>
          {/* fifteen */}
          <tr>
            <td>15</td>
            <td>
              Strengthening and improvement of 2/4 lane with paved shoulder
              Jind-Karnal section of NH-709A from design km. 68.857 to design
              km. 82.227 in the state of Haryana on EPC Basis.
            </td>
            <td>CEMOSA</td>
            <td>Haryana</td>
            <td>Completed</td>
            <td>Falling Weight Deflectometer</td>
          </tr>
          {/* sixteen */}
          <tr>
            <td>16</td>
            <td>
              Network Survey Vehicle (NSV), Falling Weight Deflectometer (FWD),
              Axel Load, Core cutting and Test Pits at Trichy – Thanjavur
              section (km. 80.00 to km. 135.750) & Madurai - Tuticorin section
              (km. 138.800 to km. 266.865) (Total-Length-184.565) km.
            </td>
            <td>Cube Highways</td>
            <td>Tamil Nadu</td>
            <td>Completed</td>
            <td>
              Network Survey Vehicle, Falling Weight Deflectometer, Axel Load,
              Core cutting and Test pits
            </td>
          </tr>
          {/* seventeen */}
          <tr>
            <td>17</td>
            <td>
              Bridge load test at CONSTRUCTION OF ROADS AND DRAINAGE SYSTEM IN
              ADDU CITY, MALDIVES
            </td>
            <td>Afcons Infrastructure Ltd.</td>
            <td>Maldives</td>
            <td>Running</td>
            <td>Bridge Load</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PaveProject;
