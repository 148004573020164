/* eslint-disable react/prop-types */
import { motion } from "framer-motion";

const Marquee = ({ imagesUrl, direction }) => {
  return (
    <div className="flex w-full overflow-hidden relative h-[19.5vh]">
      <div className="h-full w-[30%] bg-gradient-to-r from-[#fcfaf8] to-transparent absolute top-0 left-0 z-10"></div>
      <motion.div
        initial={{ x: direction === "left" ? "0" : "-100%" }}
        animate={{ x: direction === "left" ? "-100%" : "0%" }}
        transition={{ ease: "linear", duration: 50, repeat: Infinity }}
        className="flex flex-shrink-0 py-10 gap-10"
      >
        {imagesUrl.map((item, index) => {
          return <img src={item} key={index} />;
        })}
      </motion.div>
      <motion.div
        initial={{ x: direction === "left" ? "0" : "-100%" }}
        animate={{ x: direction === "left" ? "-100%" : "0%" }}
        transition={{ ease: "linear", duration: 50, repeat: Infinity }}
        className="flex flex-shrink-0 py-10 gap-10"
      >
        {imagesUrl.map((item, index) => {
          return <img src={item} key={index} />;
        })}
      </motion.div>
      <div className="h-full w-[20%] bg-gradient-to-r from-transparent to-[#fcfaf8] absolute top-0 right-0 z-10"></div>
    </div>
  );
};

export default Marquee;
