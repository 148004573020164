/* eslint-disable no-unused-vars */
import React, { useState } from "react";

const JobThree = () => {
  const [title, setTitle] = useState("Geotechnical Engineer (Reporting)");
  const [qualification, setQualification] = useState(
    "B.Tech in CE Mandatory,  M.Tech in Geotech (Preferred)"
  );
  const [experience, setExperience] = useState("1-2 Years Experience");
  const [location, setLocation] = useState("Jaipur, Rajasthan");

  const [work, setWork] = useState(
    "Handling Multiple Geotech Projects in Pan India."
  );

  const [description, setDescription] = useState(
    "To help us get started smoothly, could you share these essentials with us:- Resume, Your current CTC, Expected Salary, Salary Slip (Last 3 months)"
  );
  const [email, setEmail] = useState("info@ttlengineering.com");

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(description)}`;

  const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(description)}`;

  // Detect if it's a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div className="shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out hover:-translate-y-2 bg-[#f2f2f2] p-4 rounded-md">
      <div>
        <h1 className="text-2xl font-bold py-3">{title}</h1>
        {/* <p>{description}</p> */}
        <p className=" mb-1">
          <span className=" font-semibold">Qualification</span> :-{" "}
          {qualification}
        </p>
        <p className=" mb-1">
          <span className=" font-semibold">Experience</span> :- {experience}
        </p>
        {/* <p className=" mb-1">
          <span className=" font-semibold">Work</span> :- {work}
        </p> */}
        <p className=" mb-1">
          <span className=" font-semibold">Location</span> :- {location}
        </p>
      </div>
      <div className="lg:mt-16">
        <a
          href={isMobile ? mailtoLink : gmailLink}
          target={isMobile ? "_self" : "_blank"}
          rel={isMobile ? "" : "noopener noreferrer"}
        >
          <button className="link-btn mt-5">Apply</button>
        </a>
      </div>
    </div>
  );
};

export default JobThree;
