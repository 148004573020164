const FoundationContent = () => {
  return (
    <div className="textContentScroller relative mt-10">

      <div>
        <div className="mb-3">
          <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
            Static Pile Load Test by Kenteledge & Anchor Method
          </h1>
        </div>
        <div className="mb-3">
          <h1 className="sm:text-2xl text-2xl font-semibold mb-4">
            Kenteledge
          </h1>
          <p className="textContent md:text-[18px] text-[15px]">
            The Kentledge method involves the development of a platform upon
            which weights are positioned. These weights endure down at the
            piles, placing them beneath the load. The gauges then use degree
            resistance, motion of the pile and other readings to decide the
            design load of the piles.
          </p>
        </div>

        <div className="mb-3">
          <h1 className="sm:text-2xl text-2xl font-semibold mb-4">
            Anchor Method
          </h1>
          <p className="textContent md:text-[18px] text-[15px]">
            The take a look at system entails making use of an axial load to the
            pinnacle of the test pile with one or more hydraulic jacks. The
            reaction force is transferred to the anchor piles that go into
            anxiety inside the case of a static load in compression; or into
            compression within the case of a static load test in tension.
          </p>
        </div>
      </div>
      
      <div>
        <div className="mb-3">
          <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
            Vertical, Pull Out & Lateral Load test
          </h1>
        </div>
        <div className="mb-3">
          <h1 className="sm:text-2xl text-2xl font-semibold mb-4">Vertical</h1>
          <p className="textContent md:text-[18px] text-[15px]">
            The pile head is prepared, and a bearing plate is located for jack
            guide. Kentledge is designed for the desired response, and
            settlement is monitored with dial gauges. Load software is special
            based totally on the take a look at type, and the check load is
            maintained for twenty-four hours.
          </p>
        </div>

        <div className="mb-3">
          <h1 className="sm:text-2xl text-2xl font-semibold mb-4">Lateral</h1>
          <p className="textContent md:text-[18px] text-[15px]">
            Horizontal load tests involve putting the jack horizontally between
            piles. Load increments are applied, and displacement is monitored.
            The safe load is determined based at the very last load at a
            designated displacement or the entire displacement at a sure load
            degree.
          </p>
        </div>
        <div className="mb-10">
          <h1 className="sm:text-2xl text-2xl font-semibold mb-4">Pull-out </h1>
          <p className="textContent md:text-[18px] text-[15px]">
            Require a setup to offer uplift force to the piles. Load increments
            and displacements comply with the vertical load check case. The safe
            load is decided by thinking about the general displacement or load
            corresponding to a distinctive permissible lift, ensuring the burden
            displacement curve suggests no clear damage.
          </p>
        </div>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Dynamic Pile Load
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          High strain Dynamic trying out is a method of checking out deep
          foundations to attain statistics approximately their potential and
          integrity, and in a few cases, to reveal their set up.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Cross Hole Sonic Logging Integrity
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Cross-Hole sonic logging (CSL) is an NDT technique which entails
          ultrasonic signal transmission via the shaft between parallel water
          filled get admission to tubes. The get admission to tubes are
          frequently tied to the rebar cage and solid permanently into the
          shaft. the total wide variety of get admission to tubes typically
          depends on the diameter of the shaft.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Bi-directional static load (BDSLT)
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Bi-directional integration testing includes verifying that statistics
          exchanged between connected systems is appropriately transferred and
          efficiently mapped in each instructions.
        </p>
      </div>
    </div>
  );
};

export default FoundationContent;
