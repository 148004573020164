import { motion } from "framer-motion";
import { Power4 } from "gsap/all";
import Wrapper from "../Wrapper";
import { FaRegHandPointRight } from "react-icons/fa";
import homeBanner from "../../assets/homeBanner.jpg"

const HeroBannerText = () => {
  return (
    <Wrapper className="mt-10 w-full">
      <h1 className="text-[#232c65] font-semibold text-3xl overflow-hidden">
        <motion.span
          initial={{ rotate: 90, y: "100%", opacity: 0 }}
          animate={{ rotate: 0, y: 0, opacity: 1 }}
          transition={{ ease: Power4.easeInOut, duration: 1.2 }}
          className="inline-block origin-left"
        >
          {" "}
          <h1> Welcome to TTL Engineering</h1>
        </motion.span>
      </h1>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-16 overflow-y-auto overflow-x-hidden">
        <motion.div
          initial={{ x: "-20%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ ease: Power4.easeInOut, duration: 1.2 }}
          className="flex flex-col gap-5 mt-8"
        >
          <p>
            <span className="font-bold">TTL Engineering Private Limited</span>{" "}
            is a fast-growing engineering and advisory consultancy firm in
            India, known for its young leadership and expert team. Founded in
            2016, the company is head-quartered in Gurugram, with a regional
            office and NABL Accredited Laboratory in Jaipur, and a Data
            Processing Centre in Pilani.
          </p>
          <p>
            <span className=" font-bold">TTL Engineering</span> offers a wide
            range of services to meet the needs of the engineering and
            infrastructure sectors. Our core services include Geotechnical
            Investigation, Technical Consultancy and Pavement Testing, supported
            by advanced techniques such as Network Survey Vehicle (NSV) and
            Falling Weight Deflectometer (FWD) for the overlay and
            infrastructure analysis. Focus with cutting-edge technology and a
            commitment to continuous accuracy, TTL Engineering delivers superior
            results, making us a trusted client base that values project quality
            and innovation.
          </p>
        </motion.div>
        <motion.div
          initial={{ x: "20%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ ease: Power4.easeInOut, duration: 1.2 }}
          style={{
            width: "100%",
            backgroundImage: `url(${homeBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          }}
          className="overflow-hidden"
        >
          <div className="homeBannerCard sm:p-6 p-3 h-full overflow-hidden">
            <h1 className="text-2xl font-semibold mb-2">Our Vision</h1>
            <p className="flex gap-3 font-semibold sm:text-[16px] text-sm">
              <FaRegHandPointRight className="w-24 h-24 -mt-9" />
              Our vision is to provide the best consulting services to the
              global community and to maintain long-term relationships with our
              clients by getting the best return on their investment. We are
              committed to providing the best products and satisfying our
              customers by providing the best value engineering and reliable
              solutions.
            </p>
            <h1 className="text-2xl font-semibold mb-2 mt-3">Our Mission</h1>
            <p className="flex gap-3 font-semibold sm:text-[16px] text-sm">
              <FaRegHandPointRight className="w-24 h-24 -mt-9" />
              To be a leading, customer-focused infrastructure consultancy,
              delivering top-tier Geotechnical Investigation, Technical
              Consultancy, and Pavement Testing through innovative and advanced
              cutting edge technology whilst setting business standards and
              building strong client relationships.
            </p>
          </div>
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default HeroBannerText;
