/* eslint-disable react/prop-types */
import { cn } from "../../Utils/cn";
import { FaLocationDot } from "react-icons/fa6";

const ContactLoc = () => {
  const features = [
    {
      title: "Headquarter: Haryana",
      location:
        "Unit 103, First Floor, Iris Tech Park, Sec-48, Sohna Road, Gurugram 122018, Haryana",
      googleMapsLink:
        "https://www.google.com/maps/place/TTL+ENGINEERING+PRIVATE+LIMITED/@28.4180031,77.0338679,17z/data=!3m1!4b1!4m6!3m5!1s0x390d17e6cac0a56b:0x6b9622c622bb0d4f!8m2!3d28.4179984!4d77.0364428!16s%2Fg%2F11hx8ln5vw?entry=ttu",
      icon: <FaLocationDot className="w-6 h-6" />,
    },
    {
      title: "Corporate Office: Jaipur ",
      location:
        "TC Tower, Nirman Nanagr, Mansarovar, Jaipur 302019, Rajasthan",
      googleMapsLink:
        "https://www.google.com/maps/place/TTL+Engineering+pvt+ltd/@26.8797742,75.7524372,17z/data=!3m1!4b1!4m6!3m5!1s0x396db5dd87cd4e25:0x1d1847ab57f83e73!8m2!3d26.8797694!4d75.7550121!16s%2Fg%2F11fhz1gwz8?entry=ttu",
      icon: <FaLocationDot className="w-6 h-6" />,
    },
    {
      title: "Regional Office: Pilani",
      location:
        "A-21, Rajgarh Road, Pilani, Distt. Jhunjhunu 333031, Rajasthan ",
      googleMapsLink:
        "https://www.google.com/maps/search/A-21,+Rajgarh+Road,+Pilani,+Distt.+Jhunjhunu,+Rajasthan+333031/@28.3660422,75.5934694,16.6z?entry=ttu",
      icon: <FaLocationDot className="w-6 h-6" />,
    },
  ];

  return (
    <div className="contact-card my-20">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative z-10 py-5 max-w-7xl mx-auto sm:px-8 px-1">
        {features.map((feature, index) => (
          <Feature key={feature.title} {...feature} index={index} />
        ))}
      </div>
    </div>
  );
};

const Feature = ({
  title,
  location,
  googleMapsLink,
  index,
  icon,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col py-5 relative group/feature border-neutral-800",
        index < 2 && "md:border-r  border-neutral-800",
        (index === 0 || index === 4) && "md:border-l border-neutral-800"
      )}
    >
      <div className="mb-2 relative z-10 px-1 pl-5 text-neutral-600">
        {icon}
      </div>
      <div className="text-lg font-bold mb-2 relative z-10 px-1 pl-5">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-10 w-1 rounded-tr-full rounded-br-full bg-neutral-700 group-hover/feature:bg-[#232c65] transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-800">
          {title}
        </span>
      </div>
      <div className="flex items-start gap-5 px-1 pl-5 mb-1">
        <a
          href={googleMapsLink}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          {location}
        </a>
      </div>
    </div>
  );
};

export default ContactLoc;
