/* eslint-disable react/no-unknown-property */
import { FaLocationDot } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import logo from "/ttl-logo.jpeg";
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import { Example } from "./ClipPathLinks";

const Footer = () => {
  return (
    <footer className="relative bg-[#dceaf5] z-10 dark:bg-dark pt-10 pb-10 lg:pt-[70px] lg:pb-10">
      <Wrapper className="mx-auto max-w-screen-2xl flex justify-center items-center sm:ml-0 -ml-8">
        <div className="flex flex-wrap">
          <div className="w-full lg:px-4 pl-7 lg:w-[35%] md:w-1/2">
            <div className="w-full mb-10">
              <h4 className="text-lg flex items-center gap-5 font-semibold text-dark mb-9">
                Contacts
              </h4>
              <ul className="-mt-5 flex flex-col gap-5">
                <div className="footer-email">
                  <li>
                    <a
                      href="mailto:info@ttlengineering.com,"
                      className="text-base leading-tight text-body-color hover:text-primary dark:text-dark-6 font-semibold flex items-center gap-2"
                    >
                      <MdMailOutline /> info@ttlengineering.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:projects@ttlengineering.com"
                      // onClick={handleClick}
                      className="text-base leading-tight text-body-color hover:text-primary dark:text-dark-6 font-semibold flex items-center gap-2"
                    >
                      <MdMailOutline />
                      projects@ttlengineering.com
                    </a>
                  </li>
                </div>
                <div className="footer-no flex flex-col gap-2">
                  <li>
                    <div className=" text-base leading-tight text-body-color hover:text-primary dark:text-dark-6 flex items-center gap-2">
                      <IoMdCall />{" "}
                      <p>
                        {" "}
                        <span className="font-bold">Vikas Kirodiwal:</span> +91
                        9818110945
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="text-base leading-tight text-body-color hover:text-primary dark:text-dark-6 flex items-center gap-2">
                      <IoMdCall />{" "}
                      <p>
                        {" "}
                        <span className="font-bold">Nitin Upreti:</span> +91
                        9868163777
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="text-base leading-tight text-body-color hover:text-primary dark:text-dark-6 flex items-center gap-2">
                      <IoMdCall />
                      <p>
                        {" "}
                        <span className="font-bold">Anujay Dutt:</span> +91
                        9257032300
                      </p>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <div className="w-full lg:px-4 px-7 md:w-1/2 lg:w-[35%]">
            <div className="w-full mb-10">
              <h4 className="text-lg font-semibold text-dark mb-9">Address</h4>
              <ul className="-mt-5 flex flex-col gap-5">
                <li>
                  <Link
                    target="_blank"
                    to="https://www.google.com/maps/place/TTL+ENGINEERING+PRIVATE+LIMITED/@28.4180031,77.0338679,17z/data=!3m1!4b1!4m6!3m5!1s0x390d17e6cac0a56b:0x6b9622c622bb0d4f!8m2!3d28.4179984!4d77.0364428!16s%2Fg%2F11hx8ln5vw?entry=ttu"
                    className="text-base flex items-center gap-5 leading-tight text-body-color hover:text-primary dark:text-dark-6"
                  >
                    <div className="flex gap-4">
                      {" "}
                      <FaLocationDot className=" lg:-ml-9 md:-ml-12 ml-0 md:text-xl text-2xl" />
                      <div>
                        <span className=" font-semibold">Head Office:</span>{" "}
                        Unit 103, First Floor, Iris Tech Park, Sec-48, Sohna
                        Road, <span className=" font-bold">Gurugram</span>,
                        122018, Haryana
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.google.com/maps/place/TTL+Engineering+pvt+ltd/@26.8797742,75.7524372,17z/data=!3m1!4b1!4m6!3m5!1s0x396db5dd87cd4e25:0x1d1847ab57f83e73!8m2!3d26.8797694!4d75.7550121!16s%2Fg%2F11fhz1gwz8?entry=ttu"
                    className="text-base flex items-center gap-5 leading-tight text-body-color hover:text-primary dark:text-dark-6"
                  >
                    <div className="flex gap-4">
                      <FaLocationDot className=" lg:-ml-9 md:-ml-12 ml-0 md:text-xl text-2xl" />
                      <div>
                        <span className=" font-semibold">
                          Corporate Office:
                        </span>{" "}
                        Basement & Ground Floor, TC Tower, Nirman Nanagr,
                        Mansarovar, <span className=" font-bold">Jaipur</span>,{" "}
                        <br />
                        302019, Rajasthan
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:px-10 px-7 sm:w-1/2 lg:w-[30%]">
            <div className="w-full mb-10">
              <h4
                className="text-lg font-semibold text-dark lg:w-[70%] md:w-[60%] sm:w-[70%] w-[220px] dark:text-white
               mb-9"
              >
                <img src={logo} alt="" />
              </h4>
              <div>
                <Example />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <div>
        <span className="absolute left-0 bottom-0 z-[-1]">
          <svg
            width="217"
            height="229"
            viewBox="0 0 217 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
              fill="url(#paint0_linear_1179_5)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1179_5"
                x1="76.5"
                y1="281"
                x2="76.5"
                y2="1.22829e-05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#13C296" stopOpacity="0.08" />
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute top-10 right-10 z-[-1]">
          <svg
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
              fill="url(#paint0_linear_1179_4)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1179_4"
                x1="-1.63917e-06"
                y1="37.5"
                x2="75"
                y2="37.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#13C296" stopOpacity="0.31" />
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
