import { IoLogoWhatsapp } from "react-icons/io";
const Whatsapp = () => {
  return (
    <div>
      <a href="https://wa.me/+919696543351" target="_blank">
        <h1 className="bg-[#fcfaf8] rounded-full p-2 shadow-md">
          <IoLogoWhatsapp className="text-[#22DB22] w-12 h-12" />
        </h1>
      </a>
    </div>
  );
};

export default Whatsapp;
