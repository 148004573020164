/* eslint-disable no-unused-vars */
import { useState } from "react";
import Marquee from "./Marquee";
import client1 from '../../assets/client/client1.png'
import client2 from '../../assets/client/client2.png'
import client3 from '../../assets/client/client3.png'
import client4 from '../../assets/client/client4.png'
import client5 from '../../assets/client/client5.png'
import client6 from '../../assets/client/client6.png'
import client7 from '../../assets/client/client7.png'
import client8 from '../../assets/client/client8.png'
import client9 from '../../assets/client/client9.png'
import client10 from '../../assets/client/client10.png'
import client11 from '../../assets/client/client11.png'
import client12 from '../../assets/client/client12.png'
import client13 from '../../assets/client/client13.png'
import client14 from '../../assets/client/client14.png'
import client15 from '../../assets/client/client15.png'
import client16 from '../../assets/client/client16.png'
import client17 from '../../assets/client/client17.png'
import client18 from '../../assets/client/client18.png'
import client19 from '../../assets/client/client19.png'
import client20 from '../../assets/client/client20.png'
import client21 from '../../assets/client/client21.png'
import client22 from '../../assets/client/client22.png'
import client23 from '../../assets/client/client23.png'
import client24 from '../../assets/client/client24.png'
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";


const Marquees = () => {
  const [marqueeImages, setMarqueeImages] = useState([
    [
      client1,
      client2,
      client3,
      client4,
      client5,
      client6,
      client7,
      client8,
      client9,
      client10,
      client11,
      client12,
      client13,
      client14,
      client15,
      client16,
      client17,
      client18,
      client19,
      client20,
      client21,
      client22,
      client23,
      client24,
    ],
  ]);
  return (
    <div className="mt-10 w-full relative overflow-hidden">
      <Wrapper>
        <div className="flex flex-col items-center text-center justify-center overflow-hidden mb-2">
          <div className="relative inline-block">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
             Client & Partners
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
        </div>
      </Wrapper>
      {marqueeImages.map((item, index) => {
        return (
          <Marquee
            key={index}
            imagesUrl={item}
            direction={index === 0 ? "left" : "right"}
          />
        );
      })}
    </div>
  );
};

export default Marquees;
