/* eslint-disable react/no-unescaped-entities */
const PaveContentText = () => {
  return (
    <div className="textContentScroller relative mt-10 max-h-[100vh] overflow-y-auto overflow-x-hidden">
      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Network survey vehicle
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The community Survey car is primarily based on the modern survey
          techniques utilizing Laser, global Positioning gadget and Video
          picture processing system and so on. The Survey car is used for
          automated series of avenue stock and pavement condition associated
          records required for street Asset control, Pavement protection manage
          device and street protection Audit related research.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Mobile Bridge Inspection Unit
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The cell Bridge Inspection Unit (referred to as MBIU) is an
          electro-robotically pushed articulated shape established on a vehicle
          (a truck) as an aid in the direction of proper inspection of bridges
          or comparable infrastructure by means of allowing an inspection group
          of three-four persons wearing units to get right of entry to the
          underside of bridge decks over flowing rivers or gorges.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Falling Weight Deflectometer
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The Falling Weight Deflectometer (FWD) is a device that is used to
          assess pavement and pavement layer stiffness. it's a trailer-set up
          device that operates by dropping a weight directly onto the pavement
          and measuring the resulting pavement deflections.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Roughness Index Survey using Bump Indicator Method
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A bump integrator is a tool used within the rear of automobiles, It
          analyzes unevenness in roads, and you will quantify the usage of
          virtual counters embedded in an Lcd display screen.
        </p>
        <p className="textContent md:text-[18px] text-[15px] mb-5">
          An integrator is a low-value device geared up onto the back of a
          trailer that allows measuring the irregularities on roads while
          riding.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Retro Reflectometer (Road Marking & Signages)
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          In the simplest of terms, a Retroreflectometer is an instrument that
          assesses a pavement marking or sign's midnight visibility. Those
          instruments offer an accurate visibility evaluation by way of
          measuring the retro reflectivity of the fabric examined.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Benkelman Beam Deflection
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The Benkelman beam approach is, as a consequence extensively used for
          the evaluation of the structural capability of current bendy pavements
          and additionally for the estimation and layout of flexible overlays
          for the strengthening of any weak pavement on highways. The Benkelman
          beam measures the deflection of a flexible pavement below a moving
          wheel load.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Road safety & environment
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The impact of road protection is going to continue. similar to
          ensuring an easier, more comfortable, and more cozy shuttle, more
          secure roads also have a wonderful impact on the environment.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Illuminance Meter (LUX)
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A lux measuring device, or lux meter is therefore a device for
          determining illuminance. The illuminance is a cost that is not
          discussed with the light supply but with the illuminated location. The
          lux meter may be used to determine how a much of the luminous flux
          emitted by one or more mild sources reaches a selected floor.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Skid resistance
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Skid resistance is the pressure advanced when a tire that is prevented
          from rotating slides along the pavement floor. Pavement friction is
          the result of tire-pavement interaction.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">Bridge Load</h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The bridge loading test is one of the techniques for bridge
          rehabilitation and reinforcement to assess the bridge loads sporting
          potential and the durability of fatigue for metal members.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          CC & BC Core Cutting with Rebar Locator
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The Core cutting approach is a discipline and laboratory technique to
          calculate the weight density of soil roughly for cohesive soil.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Material Investigation include Subgrade/Subbase/ROW and Borrow Area
        </h1>
        <p className="textContent md:text-[18px] text-[15px]"></p>
      </div>
    </div>
  );
};

export default PaveContentText;
