import { motion } from "framer-motion";
import Wrapper from "../../Wrapper";
import CertificateOne from "./OneCertificate/CertificateOne";
import CertificateTwo from "./TwoCertificate/CertificateTwo";
import CertificateThree from "./ThreeCertificate/CertificateThree";
import CertificateFour from "./FourCertificate/CertificateFour";
import CertificateFive from "./FiveCertificate/CertificateFive";

const Certification = () => {
  return (
    <div className="mb-10">
      <Wrapper className="my-1">
        <div className="flex flex-col items-center text-center justify-center overflow-hidden mt-5">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Certifications
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px] leading-tight"
          ></motion.p>
        </div>
      </Wrapper>

      <Wrapper className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-5">
        <CertificateOne />
        <CertificateTwo />
        <CertificateThree />
        <CertificateFour />
        <CertificateFive />
      </Wrapper>
    </div>
  );
};

export default Certification;
