import Wrapper from "../Wrapper";
import SectorsCards from "./SectorsCard/SectorsCards";
import { motion } from "framer-motion";

const Sectors = () => {
  return (
    <div className="md:mt-16 sm:mt-0 -mt-14">
      {/* sm:mt-0 -mt-16 */}
      {/* md:mt-32 sm:mt-0 -mt-10 */}
      <Wrapper>
        <div className="flex flex-col items-center text-center justify-center overflow-hidden py-2">
          <div className="relative inline-block">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Sectors
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
        </div>
        <SectorsCards />
      </Wrapper>
    </div>
  );
};

export default Sectors;
