/* eslint-disable react/no-unescaped-entities */
const NonDestContent = () => {
  return (
    <div className="textContentScroller relative mt-10">
      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Ultrasonic Pulse Velocity
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Ultrasonic pulse velocity (UPV) is used to check the quality of
          concrete and additionally detect defects inside the concrete through
          passing electronic waves via the concrete.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Rebound Hammer
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The concrete’s compressive strength can be determined quickly and
          without problems decided with the use of the non-detrimental rebound
          hammer test. Rebound hammers, also known as Schmidt hammers, are made
          from a spring-controlled mass that travels down the plunger interior
          of a cylindrical casing.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Half Cell Potential Meter
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The half-mobile capacity at is the best corrosion monitoring method
          standardized for corrosion Potentials of uncoated reinforcing metal in
          concrete. It's far widely used to determine the possibility of
          corrosion within the rebar in bolstered concrete structures.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Carbonation
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Carbonation testing offers a way with which the inspector can
          determine the volume of carbon dioxide infiltration into the concrete.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Pile Integrity Testing
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The PIT take a look at consists of attaching an accelerometer to the
          foundation and the use of a hammer to impact it.
        </p>
        <p className="textContent md:text-[18px] text-[15px] mb-5">
          The PIT collects the acceleration information and shows curves that
          display any widespread changes in pass segment that could exist along
          the shaft.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Ground Penetrating Radar
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Ground penetrating radar (GPR) is a geophysical finding method that
          uses radio waves to capture photos beneath the surface of the ground
          in a minimally invasive manner.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Rebar Locator
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Rebar locators are used to find the position of metallic reinforcement
          bars in concrete structures and to measure their concrete cover.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          NDT & Structure Health Assessment and Rehabilitation Recommendation
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          It is a manner that includes the non-stop or periodic tracking of
          structures together with homes, bridges, dams, and infrastructure
          systems to assess their integrity and detect capability damage or
          deterioration.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Transit Dynamic Response
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A brief Dynamic reaction (TDR) check is a fast approach to assessing
          the integrity of both pre-forged and forged in situ concrete piles.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">Vibrometer</h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A vibrometer, also called a vibration meter or analyzer, is a digital
          device that measures the vibrations of a structure or system. It works
          by detecting and reading minute displacements or accelerations in an
          item.
        </p>
      </div>
    </div>
  );
};

export default NonDestContent;
