/* eslint-disable react/no-unknown-property */
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import ContactLoc from "./ContactLoc";

const Contact = () => {
  document.title = "TTL-Engineering | Contact";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Optimistic UI update
    toast.info("Sending your message...", {
      autoClose: false,
      toastId: "sendingEmail",
    });

    const API_URL =
      import.meta.env.VITE_API_URL ||
      "https://ttl-backend-website.onrender.com";

    try {
      await axios.post(`${API_URL}/api/send-email`, formData);
      toast.dismiss("sendingEmail");
      toast.success("Email sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      toast.dismiss("sendingEmail");
      toast.error("Failed to send email. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <section className="contact-form">
        <div className="flex text-center flex-col mt-10 justify-center items-center mb-10">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Contact
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            Get in touch with TTL engineering for expert consultation and
            services in Geotechnical, Foundation, Pavement testing and more.
          </motion.p>
        </div>

        <div className="contactForm overflow-y-auto overflow-x-hidden">
          <motion.div
            initial={{ x: "-20%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1 }}
          >
            <form onSubmit={handleSubmit}>
              <h1 className="sub-heading">Drop Your Message!</h1>
              <p className="para2">
                Contact us for a quote, help to join the team.
              </p>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="input"
                placeholder="Your name"
                disabled={isSubmitting}
              />
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="input"
                placeholder="Your email"
                disabled={isSubmitting}
              />
              <input
                type="text"
                name="subject"
                required
                value={formData.subject}
                onChange={handleChange}
                className="input"
                placeholder="Your subject"
                disabled={isSubmitting}
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="input overflow-y-auto overflow-x-hidden"
                cols="30"
                rows="8"
                placeholder="Your message..."
                disabled={isSubmitting}
              ></textarea>
              <button
                type="submit"
                className={`input submit ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </form>
          </motion.div>

          <motion.div
            initial={{ x: "20%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1 }}
            className="map-container"
          >
            <div className="mapBg"></div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.759617004799!2d77.033894232551!3d28.417964918126373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d17e6cac0a56b%3A0x6b9622c622bb0d4f!2sTTL%20ENGINEERING%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1714396501413!5m2!1sen!2sin"
                width="700"
                height="550"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </motion.div>
        </div>
      </section>
      <ContactLoc />
    </>
  );
};

export default Contact;
