import Wrapper from "../Wrapper";
import buss from "../../assets/bussines.jpg";
import { motion } from "framer-motion";
import PavementTabs from "./PavementTabs";

const PavementSurvey = () => {
  document.title = "TTL-Engineering | PavementSurvey";

  return (
    <div>
      {" "}
      <div className="w-full h-[40vh] mb-10">
        <img className="w-[200%] h-full object-cover" src={buss} alt="" />
      </div>
      <Wrapper>
        <div className="flex text-center overflow-hidden flex-col mt-10 justify-center items-center mb-10">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl mb-1"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Pavement Survey
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
            className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            TTL Engineering provides Pavement Investigation, condition survey
            involves gathering data to assess the structural integrity, defects,
            skid resistance, and overall ride quality of the pavement.
            Traditionally, maintenance or engineering staff would use their
            experience and visual inspections to plan maintenance activities.
          </motion.p>
        </div>
      </Wrapper>
      <PavementTabs />
      <div className="mb-5"></div>
    </div>
  );
};

export default PavementSurvey;
