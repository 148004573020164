import { motion } from "framer-motion";
import { useState } from "react";
import GeoProjects from "./GeoTechProject/GeoProjects";
import PaveProject from "./PavementsProject/PaveProject";
import ConsultProject from "./ConsultancyProject/ConsultProject";

const MobileTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNum) => {
    setActiveTab(tabNum);
  };

  return (
    <motion.div
      initial={{ y: "70%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
      className="pro_contain"
    >
      <div
        className="pro_tabs"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.dataset.tab) {
            handleClick(Number(e.target.dataset.tab));
          }
        }}
      >
        <a
          className={`pro_tab tab-1 ${activeTab === 1 ? "active" : ""}`}
          href="#"
          data-tab="1"
        >
          Consultancy
        </a>
        <a
          className={`pro_tab tab-2 ${activeTab === 2 ? "active" : ""}`}
          href="#"
          data-tab="2"
        >
          Geotechnical Investigation
        </a>
        <a
          className={`pro_tab tab-3 ${activeTab === 3 ? "active" : ""}`}
          href="#"
          data-tab="3"
        >
          Pavement Survey
        </a>
        <span className="pro_highlighter"></span>
      </div>

      <div className="pro_content">
        <div
          className={`pro_content__section ${activeTab === 1 ? "visible" : ""}`}
          data-tab="1"
        >
          <ConsultProject />
        </div>
        <div
          className={`pro_content__section ${activeTab === 2 ? "visible" : ""}`}
          data-tab="2"
        >
          <GeoProjects />
        </div>
        <div
          className={`pro_content__section ${activeTab === 3 ? "visible" : ""}`}
          data-tab="3"
        >
          <PaveProject />
        </div>
      </div>
    </motion.div>
  );
};

export default MobileTabs;
