import { useState } from "react";
import { MdClose } from "react-icons/md";
import certificate5 from "../../../../assets/certificate/certificate5.jpg";
import ZoomFive from "./ZoomFive";

function CertificateFive() {
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleCertificateClick = (name) => {
    setSelectedCertificate(name);
  };

  const handleClosePreview = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className="container">
      <div className="certificates-container">
        <div className="certificates" onClick={() => handleCertificateClick("p-1")}>
          <img src={certificate5} alt="" />
          <h1 className="uppercase font-semibold">NABL CERTIFICATE</h1>
        </div>
      </div>

      <div
        className="certificates-preview z-[9999]"
        style={{ display: selectedCertificate ? "flex" : "none" }}
      >
        <div className={`preview ${selectedCertificate === "p-1" ? "active" : ""}`}>
          <MdClose className="close-icon" onClick={handleClosePreview} />
          <ZoomFive imgZoom={certificate5} />
          <h1 className="uppercase font-semibold">NABL CERTIFICATE</h1>
        </div>
      </div>
    </div>
  );
}

export default CertificateFive;
