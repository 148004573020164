/* eslint-disable react/prop-types */

import { Link } from "react-router-dom";
import { GoDot } from "react-icons/go";

const ServiceCard = ({ item }) => {
  return (
    <div>
      <div className="service-container shadow-md transition-transform duration-500 ease-in-out lg:hover:-translate-y-9 md:hover:-translate-y-9 sm:hover:-translate-y-0">
        <span className="img-bar">
          <img src={item.img} alt="" />
          <div className="bg-[#f2f2f2] w-full  absolute bottom-0 p-3">
            <h1 className="text-3xl text-center text-[#222] font-bold">
              {item.tittle}
            </h1>
          </div>
        </span>

        <div className="service-content">
          <div className="flex flex-col items-start justify-center gap-1">
            <h2 className="flex items-center justify-center font-semibold">
              <GoDot />
              {item.heading1}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading2}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading3}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading4}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading5}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading6}
            </h2>
            <h2 className="flex items-center justify-center font-semibold mt-1">
              <GoDot />
              {item.heading7}
            </h2>
          </div>
          <Link to={item?.link} className="text-center link-btn link-btn-margin">
            Read More!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
