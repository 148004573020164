import career from "../../assets/career/career.jpeg";
import Wrapper from "../Wrapper";
import CarMobileTab from "./CarMobileTab";
import CarDesktopTab from "./CarDesktopTab";

const Careers = () => {
  document.title = "TTL-Engineering | Career";

  return (
    <div>
      {" "}
      <div className="w-full h-full">
        <img src={career} className="h-[40vh] w-full object-cover" alt="" />
      </div>
      <Wrapper className="lg:hidden block mt-20">
        <CarMobileTab />
      </Wrapper>
      <div className="lg:block hidden mt-20">
        <CarDesktopTab />
      </div>
    </div>
  );
};

export default Careers;
