/* eslint-disable no-extra-boolean-cast */
import { BsChevronDown } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

const MenuDesktop = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showCatMenu, setShowCatMenu] = useState(false);
  const dropdownRef = useRef(null);

  const data = [
    { id: 1, name: "Home", url: "/" },
    { id: 2, name: "About", url: "/about-us" },
    { id: 3, name: "Service", subMenu: true },
    { id: 4, name: "Projects", url: "/projects" },
    { id: 5, name: "Career", url: "/career" },
    { id: 6, name: "Contact Us", url: "/contact-us" },
  ];

  const subMenuData = [
    { id: 1, name: "Consultancy", url: "/consultancy" },
    { id: 2, name: "Geo-Technical Investigation", url: "/geoTechnical" },
    { id: 3, name: "Pavement Survey", url: "/pavementSurvey" },
    { id: 4, name: "Foundation testing", url: "/foundationTesting" },
    { id: 5, name: "Non destructive testing", url: "/nonDestructiveTesting" },
  ];

  const isServiceActive = subMenuData.some(
    (item) => location.pathname === item.url
  );

  useEffect(() => {
    if (dropdownRef.current) {
      if (showCatMenu) {
        dropdownRef.current.style.maxHeight = `${dropdownRef.current.scrollHeight}px`;
        dropdownRef.current.style.opacity = "1";
      } else {
        dropdownRef.current.style.maxHeight = "0";
        dropdownRef.current.style.opacity = "0";
      }
    }
  }, [showCatMenu]);

  const handleSubMenuClick = (url) => {
    setShowCatMenu(false);
    navigate(url);
  };

  return (
    <div>
      <ul className="hidden md:flex items-center lg:gap-3 md:gap-0 lg:mr-0 -mr-5 font-semibold text-black/[0.7]">
        {data.map((item) => {
          return (
            <div key={item.id}>
              {!!item?.subMenu ? (
                <li
                  className={`cursor-pointer flex items-center gap-2 relative px-2 py-1 rounded-md ${
                    isServiceActive
                      ? "text-[#fcfaf8] bg-[#232c65]"
                      : "hover:text-black"
                  }`}
                  onMouseEnter={() => setShowCatMenu(true)}
                  onMouseLeave={() => setShowCatMenu(false)}
                >
                  {item.name}
                  <BsChevronDown size={14} />

                  <ul
                    ref={dropdownRef}
                    className="bg-white absolute top-8 left-0 min-w-[250px] px-1 py-1 text-black shadow-lg rounded-md overflow-hidden transition-all duration-300 ease-in-out"
                    style={{
                      maxHeight: "0",
                      opacity: "0",
                      visibility: showCatMenu ? "visible" : "hidden",
                    }}
                  >
                    {subMenuData.map((submenu) => {
                      return (
                        <li key={submenu.id}>
                          <button
                            className="h-14 flex items-center px-2 hover:text-[#fcfaf8] hover:bg-[#232c65] rounded-md mt-1 w-full text-left"
                            onClick={() => handleSubMenuClick(submenu.url)}
                            style={{
                              color:
                                location.pathname === submenu.url
                                  ? "#fcfaf8"
                                  : "",
                              backgroundColor:
                                location.pathname === submenu.url
                                  ? "#232c65"
                                  : "",
                            }}
                          >
                            {submenu.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li className="cursor-pointer">
                  <NavLink
                    className="px-2 py-1 rounded-md hover:text-[#fcfaf8] hover:bg-[#232c65]"
                    to={item?.url}
                    style={({ isActive }) => ({
                      color: isActive ? "#fcfaf8" : "",
                      backgroundColor: isActive ? "#232c65" : "",
                    })}
                  >
                    {item.name}
                  </NavLink>
                </li>
              )}
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuDesktop;
