/* eslint-disable react/prop-types */
import { cn } from "../../Utils/cn";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FaRegLightbulb } from "react-icons/fa6";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import svg from "../../assets/contactsvg/rip.f6d7c4dd.svg"

const AboutVisions = () => {
  const features = [
    {
      title: "Mission",
      description:
        "To be a leading, customer-focused infrastructure consultancy, delivering top-tier Geotechnical Investigation, Technical Consultancy, and Pavement Testing through innovative and advanced cutting edge technology whilst setting business standards and building strong client relationships.",
      icon: <HiOutlineRocketLaunch className="w-8 h-8" />,
    },
    {
      title: "Vision",
      description:
        "Our vision is to provide the best consulting services to the global community and to maintain long-term relationships with our clients by getting the best return on their investment. We are committed to providing the best products and satisfying our customers by providing the best value engineering and reliable solutions.",
      icon: <FaRegLightbulb className="w-8 h-8" />,
    },
    {
      title: "Values",
      description:
        "We encourage collaboration and teamwork by creating a cohesive team where all members support and encourage each other. By working together, we strengthen our collective efforts, increase success, and achieve our shared goals.",
      icon: <FaHandsHoldingCircle className="w-8 h-8" />,
    },
  ];

  return (
    <div className="w-full bg-[#f2f2f2] relative my-24 transition-all">
      <div
        className="absolute lg:top-[-95px] top-[-65px] w-full h-[150px]"
        style={{
          content: '""',
          backgroundImage: `url(${svg})`,
          transform: "rotate(-180deg)",
          backgroundSize: "cover",
          transitionTimingFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
          transitionDuration: "2s",
        }}
      ></div>
      <div
        className="absolute lg:bottom-[-5vw] bottom-[-11vw] w-full h-[150px]"
        style={{
          content: '""',
          backgroundImage: `url(${svg})`,
          transform: "rotate(360deg)",
          backgroundSize: "cover",
          transitionTimingFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
          transitionDuration: "2s",
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative z-10 py-5 max-w-7xl mx-auto sm:px-8 px-1">
        {features.map((feature, index) => (
          <Feature key={feature.title} {...feature} index={index} />
        ))}
      </div>
    </div>
  );
};

const Feature = ({ title, description, icon, index }) => {
  return (
    <div
      className={cn(
        "flex flex-col py-5 relative group/feature border-neutral-800",
        index < 2 && "md:border-r  border-neutral-800",
        (index === 0 || index === 4) && "md:border-l border-neutral-800"
      )}
    >
      <div className="mb-4 relative z-10 px-10 text-neutral-600">{icon}</div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-10 w-1 rounded-tr-full rounded-br-full bg-neutral-700 group-hover/feature:bg-[#232c65] transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-800">
          {title}
        </span>
      </div>
      <p className="text-neutral-600 w-full relative z-10 px-10">
        {description}
      </p>
    </div>
  );
};

export default AboutVisions;
