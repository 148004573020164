/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import ServiceCard from "./ServiceCard";
import { motion } from "framer-motion";
import { Power4 } from "gsap";
import Wrapper from "../../Wrapper";
import { useState } from "react";
import consult from "../../../assets/service/consultancy.jpg";
import geo from "../../../assets/service/geoTech.jpg";
import pavement from "../../../assets/service/pav.jpg";

const ServiceCardData = () => {
  const [cardText, setCardText] = useState([
    {
      tittle: "Consultancy",
      heading1: "Supervision Services",
      heading2: "Feasibility Services",
      heading3: "Due Diligence Services",
      heading4: "DPR & Design",
      heading5: "Strategic Planning",
      heading6: "Financial Planning",
      heading7: "Urban Planning",
      img: consult,
      link: "/consultancy",
    },
    {
      tittle: "Geotechnical Investigation",
      heading1: "Drilling in Soil & Rock",
      heading2: "Topography Survey",
      heading3: "Vane Shear Test",
      heading4: "Cone Penetration Test",
      heading5: "California Bearing Ratio",
      heading6: "Triaxial Shear Test",
      heading7: "Direct Shear Test",
      img: geo,
      link: "/geoTechnical",
    },
    {
      tittle: "Pavement Survey",
      heading1: "Network Survey Vehicle",
      heading2: "Falling Weight Deflectometer",
      heading3: "Mobile Bridge Inspection Unit",
      heading4: "Traffic Volume Count",
      heading5: "Axle Load Survey",
      heading6: "Road Safety & Environmental",
      heading7: "Retro Reflectometers",
      img: pavement,
      link: "/pavementSurvey",
    },
  ]);
  return (
    <div className="w-full mt-10">
      <Wrapper className="mb-8">
        <div className="flex flex-col items-center text-center justify-center overflow-hidden mb-2">
          <div className="relative inline-block">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Our Service
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 sm:h-[3px] h-[3.5px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
        </div>
        <motion.p
          initial={{ y: "100%", opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2, delay: 0.2 }}
          className="w-full text-center text-[#222] sm:text-[18px] text-[14px]"
        >
          We specialize in geotechnical investigation and consultancy, offering
          expert soil drilling and testing for foundation stability. Our
          pavement testing utilizes advanced NSV and FWD technologies for
          accurate assessments. Trust us for reliable geotechnical solutions and
          pavement evaluation.
        </motion.p>
      </Wrapper>
      <Wrapper className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5">
        {cardText.map((item, index) => {
          return (
            <motion.div
              key={index}
              initial={{ y: "50%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                ease: [0.22, 1, 0.36, 1],
                duration: 1.2,
                delay: index * 0.1,
              }}
            >
              <ServiceCard key={index} item={item} />
            </motion.div>
          );
        })}
      </Wrapper>
    </div>
  );
};

export default ServiceCardData;
