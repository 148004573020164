/* eslint-disable no-unused-vars */
import Footer from "./Components/FooterPage/Footer";
import Header from "./Components/Header/Header";
import Routing from "./Helper/Routing";
import TopToScroll from "./Components/Others/TopToScroll";
import Whatsapp from "./Components/Others/Whatsapp";
import Brochure from "./Components/Others/Brochure";

const App = () => {
  
  return (
    <>
      <div className="w-full h-full">
        <Header />
        <Routing />
        <div className="flex items-end justify-center md:gap-5 gap-3 fixed z-50 md:left-10 bottom-3 left-2">
          <Brochure />
        </div>
        <div className="flex md:flex-row flex-col md:items-center items-end justify-center md:gap-5 gap-3 fixed z-50 md:bottom-5 md:right-5 right-2 bottom-2">
          <TopToScroll />
          <Whatsapp />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;
