const ConsultProject = () => {
  return (
    <div className="textContentScroller relative max-h-[70vh] overflow-y-auto overflow-x-hidden">
      <div className="overflow-x-auto tableScroll md:text-[15px] text-sm">
        <table>
          <tr>
            <th>S.N</th>
            <th>Project Tittle</th>
            <th>Client</th>
            <th>State</th>
            <th>Status</th>
            <th>Nature Of Work</th>
          </tr>
          {/* first */}
          <tr>
            <td>1</td>
            <td>
              Detail Engineering Design for Construction of Carriageway starting
              from Major Bridge over Takli River near Nayagaon Jageer Village to
              Rajasthan/MP Border (CH. 427.300 to CH.452.425) section of
              Delhi-Vodadara access controlled Green Field Alignment (NH- 148N)
              under Bharatmala Pariyojana in the state of Rajasthan on EPC mode
            </td>
            <td>
              DRA Infracon Pvt Ltd & Radhakishan Infra Developers Pvt. Ltd
            </td>
            <td>Rajasthan</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* second */}
          <tr>
            <td>2</td>
            <td>
              Detailed Engineering Design for Construction Of Four Lane
              Expressway From Vejalka Village To Start Of DSIR Zone Of Ahmedabad
              District B-1 (CH. 48+520 TO 71+060) Section Of AHMEDABAD-DHOLERA
              Greenfield Alignment (NH-751) ON EPC Mode Under BHARATMALA
              PARIYOJANA In The State Of Gujarat,
            </td>
            <td>GHV (I) Pvt. Ltd.</td>
            <td>Gujarat</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* third */}
          <tr>
            <td>3</td>
            <td>
              Detailed Engineering Design work for Four landing of Kiratpur to
              Near Chowk Section of NH-21 (From Km.12+750 to 26+500, Km.126+500
              to Km.158+500 including ACC Link Road from Km.0+000 to Km.2+003)
              Greenfield Alignment (Package-2) in the State of Himachal Executed
              on Hybrid Annuity Mode (HAM).
            </td>
            <td>Gawar Construction</td>
            <td>Himachal Pardesh</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* fourth */}
          <tr>
            <td>4</td>
            <td>
              Detailed Engineering Design for Four Landing from Malout (Design
              Km 44.500, Existing Km 80.200 of NH-07) via Abohar (Design Km
              76.500, Existing Km48.200 of NH-07) to Sadhuwali (Design Km
              33.000, Existing Km 33.000 of NH-62) Design Length = 65.000 Km in
              the State of Punjab under Bharatmala Pariyojana on Hybrid Annuity
              Mode (HAM).
            </td>
            <td>Ceigall India Ltd.</td>
            <td>Punjab</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* fifth */}
          <tr>
            <td>5</td>
            <td>
              Detailed Design for improvement of Dharuhera- Bhiwadi link road
              (NH-919, old NH-71B) to 4-lane with service road, starting from
              NH-48 (Km67.820) up to km 22.400 of NH-919(4.3km Length) in the
              state of Haryana.
            </td>
            <td>Shivalaya construction Co. Private Limited</td>
            <td>Haryana</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* sixth */}
          <tr>
            <td>6</td>
            <td>
              Detailed Design & Consultancy for Improvement of Roads connecting
              the tourist destination in Bhandara Distric (Navegaon Bandh) &
              Adhyal - Madagi - Lakhani - Lakhori - Salebhata - Mundapur - Kinni
              - Ekodi - Wadegaon - Lakhegaon - Virsi - Tiroda - Indora - To
              state Border - Khairlanji Road & State Border Amgaon Deori
              Chichgad Korchi Purada Malewada Yerkad Godlwahi Kasamsur Etapalli
              Allapali Road (4th Call). NAG-134A, 134B, 134C.
            </td>
            <td>Shivalaya construction Co. Private Limited</td>
            <td>Maharashtra</td>
            <td>Completed</td>
            <td>Detailed Engineering Design</td>
          </tr>
          {/* seven */}
          <tr>
            <td>7</td>
            <td>
              Construction of Six/Four Lane Road from R’ Block to Digha
              (Phase-1) including Flyover/Underpasses/Drains/Service Road etc.
              including Extra Dosed Bridge at Patna in the State of Bihar Length
              (0.00Km to 6.300 Km) on Engineering, Procurement & Construction
              (EPC) Mode
            </td>
            <td>BSFDCL</td>
            <td>Bihar</td>
            <td>Completed</td>
            <td>Third Party Review/Proof Consultancy</td>
          </tr>
          {/* eight */}
          <tr>
            <td>8</td>
            <td>
              Proof Checking Consultancy Services for the Underpass, Flyover
              (Overpass), Fobs and Surface Road around Huda City Centre Metro
              Station in Gurugram, Haryana on EPC mode.
            </td>
            <td>Tomer Estate Private Limited</td>
            <td>Gurugram</td>
            <td>Completed</td>
            <td>Third Party Review/Proof Consultancy</td>
          </tr>
          {/* nine */}
          <tr>
            <td>9</td>
            <td>
              Development of Bundelkhand Expressway (Package-IV) (Length 51 Km),
              (Package-V) (Length 50 Km)
            </td>
            <td>UPEIDA</td>
            <td>Uttar pradesh</td>
            <td>Completed</td>
            <td>Third Party Review/Proof Consultancy</td>
          </tr>
          {/* ten */}
          <tr>
            <td>10</td>
            <td>
              Construction of Six-Lane access-controlled highway (NH-152D)
              length 23 km on EPC Mode
            </td>
            <td>NHAI</td>
            <td></td>
            <td>Completed</td>
            <td>Third Party Review/Proof Consultancy</td>
          </tr>
          {/* eleven */}
          <tr>
            <td>11</td>
            <td>
              Construction of Eight Lane access-controlled Expressway (Length 34
              km)
            </td>
            <td>NHAI</td>
            <td></td>
            <td>Completed</td>
            <td>Third Party Review/Proof Consultancy</td>
          </tr>
          {/* twelve */}
          <tr>
            <td>12</td>
            <td>Traffic and Technical Due Diligence of 28 Road Assets</td>
            <td>British Columbia Investment Management Corporation (BCI)</td>
            <td></td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* thirteen */}
          <tr>
            <td>13</td>
            <td>
              Technical Due Diligence of 4 Landing of Chikhali to Tarsod Section
              in the state of Maharashtra
            </td>
            <td>WELSPUN Enterprise Limited</td>
            <td>Maharashtra</td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* fourteen */}
          <tr>
            <td>14</td>
            <td>
              Technical Due Diligence of Baharampur – Krishnanagar Section of NH
              12 in the State of West Bengal
            </td>
            <td>CUBE Highways</td>
            <td>West Bengal </td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* fifteen */}
          <tr>
            <td>15</td>
            <td>Due Diligence of Ramban – Banihal Section of NH 1A</td>
            <td>SC Lowy</td>
            <td></td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* sixteen */}
          <tr>
            <td>16</td>
            <td>TEV study for SOMA ISOLUX Varanasi Aurangabad Highway Ltd:</td>
            <td>PNB (outsourced from Feedback Infra)</td>
            <td>Aurangabad</td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* seventeen */}
          <tr>
            <td>17</td>
            <td>TEV Study for Baleshwar Kharagpur Expressway Ltd</td>
            <td>SBI (outsourced from Feedback Infra)</td>
            <td></td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* eighteen */}
          <tr>
            <td>18</td>
            <td>
              Contracts and Claim Management support including resolution and
              settlement for 13 PPP highway projects of Government of Punjab
              under (PIDB, PWD (B&R) and PRBDB)
            </td>
            <td>GOP through nodal agency PRBDB</td>
            <td>Punjab</td>
            <td>Completed</td>
            <td>Due Diligence / TEV / Claim Management Assignments</td>
          </tr>
          {/* nineteen */}
        </table>
      </div>
    </div>
  );
};

export default ConsultProject;
