/* eslint-disable react/no-unescaped-entities */
import { TbPointFilled } from "react-icons/tb";

const GeoInvestContent = () => {
  return (
    <div className="textContentScroller relative mt-10 max-h-[100vh] overflow-y-auto overflow-x-hidden">
      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Drilling in Soil & Rock
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A wide variety of equipment is available for performing borings and
          obtaining soil samples. The method used to advance the boring should
          be compatible with the soil and groundwater conditions to assure that
          soil samples of suitable quality are obtained. Particular care should
          be exercised to properly remove all slough or loose soil from the
          boring before sampling.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Topography Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A topographical survey is a correct depiction of a site (belongings,
          region of land, defined boundary) which is scaled and specified to
          reveal all the natural and manmade functions and their degrees.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Vane Shear Test
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The Vane Shear test is a technique of measuring the undrained shear
          strength of a cohesive soil. The test is achieved with gadget
          consisting of a rod with vanes installed to it that is inserted into
          the floor and rotated.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Static & Dynamic Cone Penetration
        </h1>
        <p className="textContent md:text-[18px] text-[15px] mb-3">
          Static Cone Penetration gives a non-stop record of version of each
          cone resistance and friction resistance with intensity.
        </p>
        <p className="textContent md:text-[18px] text-[15px]">
          Dynamic Cone Penetration (DCP) trying out is used to degree in-situ
          soil's energy and the thickness and place of subsurface soil layers
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Electric Cone Penetration Test with pore pressure measurements
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          It is a widely used geotechnical research method that involves
          measuring the resistance of soil to a penetrating cone. The ECPT check
          is important in figuring out the soil's electricity and consistency,
          which are crucial factors in designing foundation systems,
          embankments, and retaining partitions.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Block Vibration
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Block vibration test is used for assessment of in situ dynamic and
          damping houses of soils. The take a look at can be carried out on the
          inspiration element to investigate of the reaction of the structure if
          the dynamic load is carried out. The block vibration take a look at is
          of two sorts: Vertical vibration test and Horizontal vibration test.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          In -Situ Permeability (Percolation/ Lugaon Value test)
        </h1>
        <p className="textContent md:text-[18px] text-[15px] flex flex-col">
          This test is conducted directly on-site (in-situ) to determine the
          coefficient of permeability of overburden soils using two different
          methods:
          <span className="mt-2 flex items-center gap-1">
            <TbPointFilled />
            Constant Head Method
          </span>
          <span className="flex items-center gap-1">
            <TbPointFilled />
            Falling Head Method
          </span>
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          In -Situ Permeability of rock (Packer test)
        </h1>
        <p className="textContent md:text-[18px] text-[15px] flex flex-col">
          This test is conducted directly on-site (in-situ) to determine the
          coefficient of permeability in Bedrock using two different methods:
          <span className="mt-2 flex items-center gap-1">
            <TbPointFilled />
            Single Packer Method
          </span>
          <span className="flex items-center gap-1">
            <TbPointFilled />
            Double Packer Method
          </span>
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Plate Load (Static and Cyclic)
        </h1>
        <p className="textContent md:text-[18px] text-[15px] mb-5">
          The test consists of a circular plate (450 mm diameter) this is in
          close touch with the layer to be examined and measuring deflections
          under load increments. the weight increments have been applied through
          a hydraulic jack with a suitable load potential.
        </p>
        <p className="textContent md:text-[18px] text-[15px]">
          The cyclic plate load test is used to decide field energy and
          deformation values. several APLTs were completed using distinctive
          plate sizes, strain conditions, and cargo cycles. The test web site
          turned into prepared with the aid of excavating the topsoil. A
          bulldozer with a laser level was used to excavate the vicinity.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">Trial Pits</h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A trial pit is an excavation of floor as a way to examine or sample
          the composition and structure of the subsurface, normally dug at some
          point of a site investigation, a soil survey or a geological survey.
          Trial pits are dug before the construction. they're dug to decide the
          geology and the water table of that web site.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Sand Replacement method
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The sand substitute approach is used to determine the sector density
          of soil containing coarse particles like gravel or stones, where the
          center cutter method isn't always appropriate. The approach includes
          excavating a hole inside the compacted soil and measuring the extent
          of the hole by filling it with calibrated sand.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Double Ring Infiltrometer
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Ideal for subject trying out, as well as lab use, the Double-Ring
          Infiltrometer affords real discipline measurements of the fee of
          infiltration of water or different liquid through soils, particularly
          best-grained soils in accordance with. The check offers reliable
          records for use in environmental and different geotechnical studies,
          along with liquid waste disposal, leaching, drainage, irrigation
          requirements, and canal or reservoir leakage.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Standard Penetration
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The usual penetration check (SPT) is an in-situ dynamic penetration
          take a look at designed to offer records on the geotechnical
          engineering homes of soil. This check is the most frequently used
          subsurface exploration drilling test accomplished worldwide.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Undisturbed soil sampling
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Undisturbed soil samples are soil samples that might be taken without
          stressing the soil's herbal shape or water content. They may be used
          to determine the soil's primary physical residences, which include its
          shear electricity, water content, soil shape, and consolidation
          properties.
        </p>
      </div>
    </div>
  );
};

export default GeoInvestContent;
