import { HoverEffect } from "./card-hover-effect";
import road from "../../../assets/sector/highway.jpg";
import bridge from "../../../assets/sector/bridge.jpg";
import metro from "../../../assets/sector/metro.jpg";
import railway from "../../../assets/sector/train.jpg";
import industrial from "../../../assets/sector/industrial.jpg";
import renewable from "../../../assets/sector/renewable.jpg";
import water from "../../../assets/sector/water.jpg";
import tunnel from "../../../assets/sector/tunnel.jpg";
import dam from "../../../assets/sector/dam.jpg";
import port from "../../../assets/sector/port.jpg";
import building from "../../../assets/sector/building.jpg";
import airport from "../../../assets/sector/airport.webp";

function SectorsCards() {
  const projects = [
    {
      title: "Road and Highway",
      image: road,
    },
    {
      title: "Bridge",
      image: bridge,
    },
    {
      title: "Metro",
      image: metro,
    },
    {
      title: "Railway",
      image: railway,
    },
    {
      title: "Industrial and Development",
      image: industrial,
    },
    {
      title: "Renewable",
      image: renewable,
    },
    {
      title: "Water and Irrigation",
      image: water,
    },
    {
      title: "Airport",
      image: airport,
    },
    {
      title: "Tunnel",
      image: tunnel,
    },
    {
      title: "Building",
      image: building,
    },
    {
      title: "Dam",
      image: dam,
    },
    {
      title: "Port",
      image: port,
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-1">
      <HoverEffect items={projects} />
    </div>
  );
}

export default SectorsCards;
