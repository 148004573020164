/* eslint-disable no-unused-vars */
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BiArrowBack } from "react-icons/bi";
import slide1 from "../../assets/slide/slide1.jpg";
import slide2 from "../../assets/slide/slide2.jpg";
import slide3 from "../../assets/slide/slide3.jpg";

const HeroBanner = () => {
  return (
    <div className="relative text-white text-[20px] w-full max-w-full mx-auto">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        renderArrowPrev={(clickHandler, hasPrev) => (
          <div
            onClick={clickHandler}
            className="absolute right-[31px] md:right-[51px] bottom-0 w-[30px]
             md:w-[50px] h-[30px] md:h-[50px] bg-black z-10 flex items-center
              justify-center cursor-pointer hover:opacity-90"
          >
            <BiArrowBack className="text-sm md:text-lg" />
          </div>
        )}
        renderArrowNext={(clickHandler, hasNext) => (
          <div
            onClick={clickHandler}
            className="absolute right-0 bottom-0 w-[30px] md:w-[50px] h-[30px]
             md:h-[50px] bg-black z-10 flex items-center
              justify-center cursor-pointer hover:opacity-90"
          >
            <BiArrowBack className="text-sm md:text-lg rotate-180" />
          </div>
        )}
      >
        <div className="flex justify-center items-center">
          <img
            className="xl:h-[75vh] lg:h-[65vh] md:h-[55vh] sm:h-[45vh] h-[35vh] aspect-auto object-cover relative"
            src={slide1}
          />
        </div>
        <div className="flex justify-center items-center">
          <img
            className="xl:h-[75vh] lg:h-[65vh] md:h-[55vh] sm:h-[45vh] h-[35vh] aspect-auto object-cover relative"
            src={slide2}
          />
        </div>
        <div className="flex justify-center items-center">
          <img
            className="xl:h-[75vh] lg:h-[65vh] md:h-[55vh] sm:h-[45vh] h-[35vh] aspect-auto object-cover relative"
            src={slide3}
          />
        </div>
      </Carousel>
    </div>
  );
};

export default HeroBanner;
