import ServiceCardData from "./ServiceCard/ServiceCardData";
import HeroBanner from "./HeroBanner";
import HeroBannerText from "./HeroBannerText";
import Marquees from "./Marquees";
import ParallaxEffect from "./ParallaxEffect";
import Sectors from "./Sectors";

const Home = () => {
  document.title =
    "Welcome to TTL Engineering Pvt Ltd | GeoTechnical Survey Company";
  return (
    <div>
      <HeroBanner />
      <HeroBannerText />
      <ServiceCardData />
      <ParallaxEffect />
      <Sectors />
      <Marquees />
    </div>
  );
};

export default Home;
