import { useState } from "react";
import { MdClose } from "react-icons/md";
import certificate2 from "../../../../assets/certificate/certificate2.jpg";
import ZoomTwo from "./ZoomTwo";

function CertificateTwo() {
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const handleCertificateClick = (name) => {
    setSelectedCertificate(name);
  };

  const handleClosePreview = () => {
    setSelectedCertificate(null);
  };

  return (
    <div className="container">
      <div className="certificates-container">
        <div className="certificates" onClick={() => handleCertificateClick("p-1")}>
          <img src={certificate2} alt="" />
          <h1 className="uppercase font-semibold">
            CERTIFICATE OF INCORPORATION
          </h1>
        </div>
      </div>

      <div
        className="certificates-preview z-[9999]"
        style={{ display: selectedCertificate ? "flex" : "none" }}
      >
        <div className={`preview ${selectedCertificate === "p-1" ? "active" : ""}`}>
          <MdClose className="close-icon" onClick={handleClosePreview} />
          <ZoomTwo imgZoom={certificate2} />
          <h1 className="uppercase font-semibold">
            CERTIFICATE OF INCORPORATION
          </h1>
        </div>
      </div>
    </div>
  );
}

export default CertificateTwo;
