/* eslint-disable react/no-unescaped-entities */
const TrafficContentText = () => {
  return (
    <div className="textContentScroller relative mt-10 max-h-[100vh] overflow-y-auto overflow-x-hidden">
      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Video Based traffic Count survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          That is a systematic way of gathering data to be used for various
          visitors engineering purposes
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Origin Destination Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          This survey spots where most motors originate in a network and finish
          their journey and it additionally indicates how regularly they take
          this direction
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Axel Load Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The survey's predominant motive is to save you increased damage to
          pavement and to provide information for price-powerful pavement design
          and maintaining present roads
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Traffic Volume Count
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The system to decide the extent of visitors or no of automobiles
          transferring on the roads at a selected segment at some point of a
          specific term.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Number (Registration) Plate Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The registration numbers of motors entering or leaving the survey
          factor positioned at the cordon line.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Fuel Cell Data from petrol pump
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The month-to-month petrol and diesel income statistics become
          accumulated from various gas stations at the assignment avenue. those
          factors are primarily based on the month smart fuel sale information
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Annual Average daily Traffic (AADT) & Average Daily Traffic (ADT)
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          AADT is the total quantity of automobile tour on a avenue for an
          entire yr, divided by means of 365. ADT is the common quantity of
          motors travelling thru a region in the course of a period shorter than
          a 12 months.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Toll Rate Calculation
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          the quantity of toll is decided based on the length of the stretch of
          motorway (generally 60 km).
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Turning Movement Count Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          these counts are frequently used to compare and put in force
          intersection improvements that effect site visitors operations,
          congestion, and protection.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Traffic Growth Estimation
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Generated site visitors is the percentage growth in traffic because of
          new journeys generated by using new tendencies.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          House hold Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          The subjects cowl many socio-economic areas which include poverty,
          health, schooling, employment, gender equality, food protection and
          get entry to to offerings.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Socio-Economic Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Socio-economic survey is a thorough have a look at that gathers,
          analyzes, and translates facts on diverse components of a populace or
          community's financial scenario, dwelling standards, schooling,
          employment status, and social characteristics. it's far taken into
          consideration a key supply of statistical information on household
          earnings and expenditure, as well as different statistics on housing,
          person and family characteristics, and living situations
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Pedestrian Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          A pedestrian survey is when we walk alongside the surface of the web
          page to find artifacts on the surface.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Parking Survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          Facts series sheets need to be numbered and consist of one line for
          each parking area. take into account to include landmarks like
          driveways, slash cuts, hydrants.
        </p>
      </div>

      <div className="mb-10">
        <h1 className="sm:text-3xl text-2xl font-semibold mb-4">
          Speed & Delay survey
        </h1>
        <p className="textContent md:text-[18px] text-[15px]">
          This survey evaluates the quality of the traffic motion along a route
          and identifies the vicinity, purpose and volume of the delays in the
          equal path.
        </p>
      </div>
    </div>
  );
};

export default TrafficContentText;
