import { motion } from "framer-motion";
import { useState } from "react";
import GeoInvestImg from "./GeoInvestSlide/GeoInvestImg";
import GeoInvestContent from "./GeoInvestText/GeoInvestContent";
import LabTestImg from "./LabTestSlide/LabTestImg";
import LabTestContent from "./LabTestText/LabTestContent";

const GeoTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNum) => {
    setActiveTab(tabNum);
  };

  return (
    <motion.div
      initial={{ y: "70%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
      className="contain"
    >
      <div
        className="tabs"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.dataset.tab) {
            handleClick(Number(e.target.dataset.tab));
          }
        }}
      >
        <a
          className={`tab tab-1 ${activeTab === 1 ? "active" : ""}`}
          href="#"
          data-tab="1"
        >
          Geotechnical Investigations
        </a>
        <a
          className={`tab tab-2 ${activeTab === 2 ? "active" : ""}`}
          href="#"
          data-tab="2"
        >
          Laboratory Testing
        </a>
        <span className="highlighter"></span>
      </div>

      <div className="content">
        <div
          className={`content__section ${activeTab === 1 ? "visible" : ""}`}
          data-tab="1"
        >
          <GeoInvestImg />
          <GeoInvestContent />
        </div>
        <div
          className={`content__section ${activeTab === 2 ? "visible" : ""}`}
          data-tab="2"
        >
          <LabTestImg />
          <LabTestContent />
        </div>
      </div>
    </motion.div>
  );
};

export default GeoTabs;
