/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";

const ZoomTwo = ({ imgZoom }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isTransitioning, setIsTransitioning] = useState(false);
  const imgZoomRef = useRef(null);
  const containerRef = useRef(null);

  const maxZoom = 2; // Maximum zoom scale
  const minZoom = 1; // Minimum zoom scale

  const handleZoomIn = () => {
    setIsTransitioning(true);
    setScale((prevScale) => Math.min(maxZoom, prevScale + 0.1));
  };

  const handleZoomOut = () => {
    setIsTransitioning(true);
    setScale((prevScale) => {
      const newScale = Math.max(minZoom, prevScale - 0.1);
      if (newScale === 1) {
        // Reset position when fully zoomed out
        setPosition({ x: 0, y: 0 });
      }
      return newScale;
    });
  };

  useEffect(() => {
    const image = imgZoomRef.current;
    const container = containerRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      setIsTransitioning(false);
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };

      setPosition((position) => {
        const newX = position.x + deltaX;
        const newY = position.y + deltaY;

        // Calculate the boundaries
        const imageWidth = image.offsetWidth * scale;
        const imageHeight = image.offsetHeight * scale;
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        // Prevent dragging beyond the original boundaries
        const maxX = Math.max(0, (imageWidth - containerWidth) / 4);
        const maxY = Math.max(0, (imageHeight - containerHeight) / 4);

        // Constrain the new positions to the boundaries
        return {
          x: Math.min(maxX, Math.max(-maxX, newX)),
          y: Math.min(maxY, Math.max(-maxY, newY)),
        };
      });
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    image?.addEventListener("mousedown", handleMouseDown);
    image?.addEventListener("mousemove", handleMouseMove);
    image?.addEventListener("mouseup", handleMouseUp);
    image?.addEventListener("mouseleave", handleMouseUp);

    return () => {
      image?.removeEventListener("mousedown", handleMouseDown);
      image?.removeEventListener("mousemove", handleMouseMove);
      image?.removeEventListener("mouseup", handleMouseUp);
      image?.removeEventListener("mouseleave", handleMouseUp);
    };
  }, [scale]);

  return (
    <div className="relative overflow-hidden" ref={containerRef}>
      <div className="absolute top-0 left-0 flex flex-col gap-1">
        <button className="zoom-in-out-btn z-30" onClick={handleZoomIn}>
          <IoMdAdd className="w-8 h-8" />
        </button>
        <button className="zoom-in-out-btn z-30" onClick={handleZoomOut}>
          <FiMinus className="w-8 h-8" />
        </button>
      </div>

      <img
        ref={imgZoomRef}
        src={imgZoom}
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          cursor: scale > 1 ? "move" : "default",
          transition: isTransitioning ? "transform 0.3s ease" : "none",
        }}
        draggable={false}
        alt=""
      />
    </div>
  );
};

export default ZoomTwo;
