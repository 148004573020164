/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { NavLink, useLocation } from "react-router-dom";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

const MenuMobile = ({ setMobileMenu }) => {
  const [showCatMenu, setShowCatMenu] = useState(false);
  const location = useLocation();

  const data = [
    { id: 1, name: "Home", url: "/" },
    { id: 2, name: "About", url: "/about-us" },
    { id: 3, name: "Service", subMenu: true },
    { id: 4, name: "Projects", url: "/projects" },
    { id: 5, name: "Career", url: "/career" },
    { id: 6, name: "Contact Us", url: "/contact-us" },
  ];

  const subMenuData = [
    { id: 1, name: "Consultancy", url: "/consultancy" },
    { id: 2, name: "GeoTechnical Investigation", url: "/geoTechnical" },
    { id: 3, name: "Pavement Survey", url: "/pavementSurvey" },
    { id: 4, name: "Foundation testing", url: "/foundationTesting" },
    { id: 5, name: "Non destructive testing", url: "/nonDestructiveTesting" },
  ];

  const isServiceActive = subMenuData.some(
    (item) => location.pathname === item.url
  );

  return (
    <div>
      <ul className="flex flex-col md:hidden font-bold absolute top-[80px] left-0 w-full h-[calc(100vh-50px)] bg-[#fcfaf8] border-t text-black">
        {data.map((item) => {
          return (
            <div key={item.id}>
              {!!item?.subMenu ? (
                <li
                  className={`cursor-pointer py-4 px-5 border-b flex flex-col relative transition-colors duration-300 rounded-md mx-1 ${
                    isServiceActive || showCatMenu
                      ? "text-[#222] bg-[#dceaf5]"
                      : "hover:text-[#fcfaf8] hover:bg-[#232c65]"
                  }`}
                  onClick={() => setShowCatMenu(!showCatMenu)}
                >
                  <div className="flex justify-between items-center">
                    {item.name}
                    <BsChevronDown size={14} />
                  </div>

                  {showCatMenu && (
                    <ul className="bg-[#f2f2f2] mt-4 -mb-4 text-[#222] mx-1 overflow-hidden">
                      {subMenuData.map((submenu) => {
                        return (
                          <NavLink
                            key={submenu.id}
                            className="py-3 px-4 border-t flex items-center transition-colors duration-300 hover:text-[#fcfaf8] hover:bg-[#232c65]"
                            to={submenu.url}
                            style={({ isActive }) => ({
                              color: isActive ? "#fcfaf8" : "",
                              backgroundColor: isActive ? "#232c65" : "",
                            })}
                            onClick={() => {
                              setShowCatMenu(false);
                              setMobileMenu(false);
                            }}
                          >
                            <MdOutlineSubdirectoryArrowRight className="mr-2" />
                            {submenu.name}
                          </NavLink>
                        );
                      })}
                    </ul>
                  )}
                </li>
              ) : (
                <NavLink
                  to={item?.url}
                  onClick={() => setMobileMenu(false)}
                  className={({ isActive }) =>
                    `block py-4 px-5 border-b transition-colors duration-300 rounded-md mx-1 ${
                      isActive
                        ? "text-[#fcfaf8] bg-[#232c65]"
                        : "hover:text-[#fcfaf8] hover:bg-[#232c65]"
                    }`
                  }
                >
                  {item.name}
                </NavLink>
              )}
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuMobile;
