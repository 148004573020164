/* eslint-disable react/no-unescaped-entities */

import { motion } from "framer-motion";
import { CareerEffect } from "../CareerCard/career-hover-effect";

const CompanyBenefit = () => {
  const projects = [
    {
      title: "Integrity",
      description:
        "We are committed to ethical and transparent practices in all aspects of our business.",
      // img: consult,
    },
    {
      title: "Innovation",
      description:
        "We embrace new technologies and innovative solutions to drive progress in the civil engineering industry.",
      // img: consult,
    },
    {
      title: "Sustainability",
      description:
        "We prioritize environmental responsibility and strive to develop sustainable infrastructure solutions.",
      // img: consult,
    },
    {
      title: "Collaboration",
      description:
        "We foster a collaborative work environment where team members can share ideas and work together towards common goals.",
      // img: consult,
    },
    {
      title: "Professional Development",
      description:
        "We invest in the growth and development of our employees, providing opportunities for continuous learning and advancement.",
      // img: consult,
    },
    {
      title: "Work-Life Balance",
      description:
        "We understand the importance of a healthy work-life balance and offer flexible schedules and generous time off benefits.",
      // img: consult,
    },
    {
      title: "Competitive Compensation",
      description:
        " We provide a competitive salary and comprehensive benefits package, including health insurance, retirement plans, and bonuses.",
      // img: consult,
    },
    {
      title: "Inclusive Culture",
      description:
        "We celebrate diversity and foster an inclusive work environment where everyone feels valued and respected.",
      // img: consult,
    },
    {
      title: "Community Involvement",
      description:
        "We encourage our employees to give back to the community through volunteer opportunities and charitable initiatives.",
      // img: consult,
    },
  ];
  return (
    <div>
      <div className="w-full h-full py-6 mt-7">
        <div className="flex text-center flex-col justify-center items-center">
          <div className="relative inline-block mb-2">
            <motion.h1
              className="text-[#232c65] sm:text-5xl text-4xl"
              initial={{ y: "70%", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            >
              Our Values and Benefits
            </motion.h1>
            <motion.div
              className="absolute bottom-0 left-0 h-[3px] bg-[#232c65] rounded"
              initial={{ width: 0, opacity: 0 }}
              whileInView={{ width: "100%", opacity: 1 }}
              viewport={{ once: true }}
              transition={{ ease: [0.22, 1, 0.36, 1], duration: 1.2 }}
            />
          </div>
          <motion.p
            initial={{ y: "80%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              ease: [0.22, 1, 0.36, 1],
              duration: 1.2,
              delay: 0.2,
            }}
            className=" w-full text-center text-[#222] sm:text-[18px] text-[14px]"
          >
            Discover TTL Engineering's core values and employee benefits. We
            prioritize integrity, excellence, and teamwork, offering
            comprehensive benefits to support our employees' well-being,
            professional development, and work-life balance.
          </motion.p>
        </div>
        <div className="max-w-6xl mx-auto px-1">
          <CareerEffect items={projects} />
        </div>
      </div>
    </div>
  );
};

export default CompanyBenefit;
