/* eslint-disable react/no-unescaped-entities */
const GeoProjects = () => {
  return (
    <div className="textContentScroller relative max-h-[70vh] overflow-y-auto overflow-x-hidden">
      <div className="overflow-x-auto tableScroll md:text-[15px] text-sm">
        <table>
          <tr>
            <th>S.N</th>
            <th>Project Tittle</th>
            <th>Client</th>
            <th>State</th>
            <th>Sectors</th>
          </tr>
          {/* first */}
          <tr>
            <td>1</td>
            <td>
              Geotechncial Investigation Works for Dewas Dam & Tunnel Project at
              Gogunda Udaipur Rajasthan.
            </td>
            <td>Megha Engineering Infrastructure Ltd.</td>
            <td>Rajasthan</td>
            <td>Dam & Tunnel</td>
          </tr>
          {/* second */}
          <tr>
            <td>2</td>
            <td>
              Geotechnical Investigation of Soil and Rock Samples for Various
              Structures for the Project Part design and construction of
              Elevated Viaduct (Chainage- 860.224m to +21316.022m) and 19
              Elevated Stations of MML-12 (KALYAN-TALOJA) - Mumbai Metro - 12.
            </td>
            <td>Gawar Construction Ltd.</td>
            <td>Maharashtra</td>
            <td>Metro</td>
          </tr>
          {/* third */}
          <tr>
            <td>3</td>
            <td>
              Geotechnical Investigation for Indore Metro UG-IN05 in the State
              of Madhya Pradesh.
            </td>
            <td>TATA Projects Ltd.</td>
            <td>Maharashtra</td>
            <td>Metro</td>
          </tr>
          {/* fourth */}
          <tr>
            <td>4</td>
            <td>
              Geotechnical Investigation for Engineering, Procurement and
              Construction (EPC) for Proposed Doubling of Track between
              Karanjgaon station (Including) at Km 69.01(Ch 69010) & Aurangabad
              station(Including) at Km 113.15(Ch 113150) [i.e. (44.14 km)]
              including Electrification and Signaling works in connection with
              Aurangabad-Ankai Doubling Project in Nanded Division of South
              Central Railway.
            </td>
            <td>H.G. Infra Engineering Limited</td>
            <td>Maharashtra</td>
            <td>Railway</td>
          </tr>
          {/* fifth */}
          <tr>
            <td>5</td>
            <td>
              Geotechnical Investigation at Aluabari – Thakurganj, Katihar –
              kumedpur – Aluabari Road, Malda Town – Kumedpur DPR Project for
              Eastern railway.
            </td>
            <td>
              Aarvee Associates Architects Engineers & Consultants Pvt. Ltd
            </td>
            <td>West bangal</td>
            <td>Railway</td>
          </tr>
          {/* sixth */}
          <tr>
            <td>6</td>
            <td>
              Geotechnical Investigation for Construction of Four Lane Elevated
              Corridor/Flyover from Maharani Laxmibai Pratima to A.B. Road (N.H.
              46) Near Girwai Police Chouki On Swarn Rekha River in Gwalior City
              (M.P.) (2nd Phase).
            </td>
            <td>PNC Infratech Limited</td>
            <td>Madhya Pradesh</td>
            <td>Road/ Highway</td>
          </tr>
          {/* seven */}
          <tr>
            <td>7</td>
            <td>
              Geotechnical Investigation for Design & Construction of Coastal
              Road from Amra Marg to MTHL Junction including Airport Link at
              Navi Mumbai package -1 & 2.
            </td>
            <td>J Kumar Infra Project</td>
            <td>Maharashtra</td>
            <td>Road/ Highway</td>
          </tr>
          {/* eight */}
          <tr>
            <td>8</td>
            <td>
              Geotechnical Investigation Works including Laboratory Testing of
              collected Samples, Preparation and Submission of Reports" for
              250MW Solar Power Corporation Limited at Kadapa, Andhra Pradesh.
            </td>
            <td>Adani Group for PCAI</td>
            <td>Andhra Pradesh</td>
            <td>Renewable</td>
          </tr>
          {/* nine */}
          <tr>
            <td>3</td>
            <td>
              Geotechnical Investigation for 75MWp Solar Power Plant at Jath,
              Maharashtra.
            </td>
            <td>Mahindra Susten Private Limited</td>
            <td>Maharashtra</td>
            <td>Renewable</td>
          </tr>
          {/* Ten */}
          <tr>
            <td>10</td>
            <td>
              Geotechnical Investigation for Green Hydrogen Plant (5000 NM3/hr.)
              at Vijayanagar, Karnataka.
            </td>
            <td>JSW Renewable Energy (VJNR) Ltd for PK Engineers</td>
            <td>Karnataka</td>
            <td>Renewable</td>
          </tr>
          {/* eleven */}
          <tr>
            <td>11</td>
            <td>
              Geotechnical Investigation for Construction of Access Controlled
              Multi-Modal Corridor from Navghar to Balavall and Jalna Nanded
              Expressway in the state of Maharashtra on EPC Mode.
            </td>
            <td>Oriental Structural Engineers Private Limited</td>
            <td>Maharashtra</td>
            <td>Road/ Highway</td>
          </tr>
          {/* twelve */}
          <tr>
            <td>12</td>
            <td>
              Geotechnical Investigation for Construction Of Access Controlled
              Multi-Modal Corridor From Navghar To Balavali Package Mmc-11 From
              Kalambsure Km 68-620 To Govirie Km 96-410 In Tq. Uran & District
              Raigad (Length-7.79 Km).
            </td>
            <td>BSCPL Infrastructure Limited</td>
            <td>Maharashtra</td>
            <td>Road/ Highway</td>
          </tr>
          {/* thirteen */}
          <tr>
            <td>13</td>
            <td>
              Geotechnical Investigation for pre bid studies at Construction of
              Nagpur-Chandrapur Access Controlled Super Communication Expressway
              in the State of Maharashtra on EPC. PACKAGE NC-06 from Km150+350
              Jogapur Tq. Ballarpur to Km182+395 Ghatkul Tq. Gondpimpari Dist.
              Chandrapur.
            </td>
            <td>BSCPL Infrastructure Limited</td>
            <td>Maharashtra</td>
            <td>Road/ Highway</td>
          </tr>
          {/* fourteen */}
          <tr>
            <td>14</td>
            <td>
              Geotechnical Investigation and Detail Design work for
              "Construction of Four Lane Expressway carriageway from Vejalka
              Village to start of DSIR Zone of Ahmedabad District B-1 (Ch.48+520
              to 71+060)-Pkg.3 Section of Ahmedabad-Dholera Greenfield Alignment
              (NH-751) on EPC Mode under Bharatmala Pariyojana in the State of
              Gujarat.
            </td>
            <td>GHV Infrastructure Ltd</td>
            <td>Gujarat</td>
            <td>Road/ Highway</td>
          </tr>
          {/* fifteen */}
          <tr>
            <td>15</td>
            <td>
              Geotechnical Investigation and Detail Design, Proof checking &
              Road safety Consultancy services for the project Construction of
              Eight Lane Carriageway starting from Major bridge over Takli river
              near Nayagaon Jageer village to Rajasthan/MP Border (Ch 427.300 to
              Ch. 452.425) section of Delhi - Vadodara Access Controlled Green
              field Alignment (NH-148N) under Bharatmala Pariyojana in the State
              of Rajasthan on EPC Mode.
            </td>
            <td>Radhakishan Infra Developers Pvt. Ltd</td>
            <td>Rajasthan</td>
            <td>Road/ Highway</td>
          </tr>
          {/* sixteen */}
          <tr>
            <td>16</td>
            <td>
              Geotechnical Investigation and Detail Design for Construction of 6
              lane access controlled Greenfield highway from km 1+900 to km
              177+658 of Rasisar-Deogarh & from km 48+000 to km 72+000 of Patau
              Khurd-Asotra section of NH-754K as part of Amritsar-Jamnagar
              Economic corridor (EC) in Rajasthan on EPC under Bharatmala
              Pariyojana (Phase-I).
            </td>
            <td>NKC Projects Pvt Ltd</td>
            <td>Rajasthan</td>
            <td>Road/ Highway</td>
          </tr>
          {/* seventeen */}
          <tr>
            <td>17</td>
            <td>
              Geotechnical investigation services for the work of Four landing
              of Ratnagiri- Kolhapur section of NH-166 (Package-1 from km. 0.000
              to km. 19.769 and km. 31.000 to km. 67.140) in the State of
              Maharashtra to be executed on Hybrid Annuity Mode under NH(O).
            </td>
            <td>Ravi Infrabuild Projects Limited</td>
            <td>Maharashtra</td>
            <td>Road/ Highway</td>
          </tr>
          {/* eighteen */}
          <tr>
            <td>18</td>
            <td>
              Geotechnical Investigation and Detail Design for Up-gradation to 6
              lane with paved shoulders of Limbdi - Sayla section of NH8A (new
              NH 27) from Km 99+000 to Km 138+000 on EPC mode in the State of
              Gujarat.
            </td>
            <td>Varaha Infra Ltd.</td>
            <td>Gujarat</td>
            <td>Road/ Highway</td>
          </tr>
          {/* nineteen */}
          <tr>
            <td>19</td>
            <td>
              Geotechnical Investigation and Detail Design of Improvement of
              Amritsar bypass by construction of additional structure and
              service Road on NH-1 between km 448+510 to km. 473+068 in the
              state of Punjab on EPC Mode.
            </td>
            <td>Varaha Infra Ltd.</td>
            <td>Punjab</td>
            <td>Road/ Highway</td>
          </tr>
          {/* twenty */}
          <tr>
            <td>20</td>
            <td>
              Geotechnical Investigation work for Construction of 2-laning with
              hard shoulder configuration of Lunglei - Chhumkhum Tlabung from
              Design Chainage Km 0.000 to Km 74.950 [Pkg - A&B] for
              Lunglei-Tlabung section of NH-302 in the State of Mizoram under
              'Bharatmala Pariyojana' on EPC Mode.
            </td>
            <td>BRN Infrastructure Pvt Ltd</td>
            <td>Mizoram</td>
            <td>Road/ Highway</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default GeoProjects
